{
  "PAYMENT_TITLE": "Payment",
  "PAYMENT_DESCRIPTION": "Scan the QR code with the RevoluPAY app or press on the link that appears below the QR (if you are viewing this on your mobile device).",
  "PAY_IN_APP": "Pay with RevoluPAY",
  "PAGE_WILL_REFRESH": "This page will refresh automatically once the payment has been completed.",
  "PAYMENT_SUCCESS_TITLE": "Payment completed",
  "PAYMENT_SUCCESS_DESCRIPTION": "Congratulations, your payment has been successfully processed. Please check your e-mail for more information.",
  "PAYMENT_ERROR_TITLE": "Payment error",
  "PAYMENT_ERROR_DESCRIPTION": "Sorry but there was an error during the payment process. Please try again.",
  "DASHBOARD": "DASHBOARD"
}