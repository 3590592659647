import { stat } from 'fs';
import { Config } from '../config';
import { Auth } from './auth.service';
import { Card } from './types/card.type';
import { CardBalance } from './types/cardBalance.type';
import { CardLimit } from './types/cardLimit.type';
import { CardRequest } from './types/cardRequest.type';
import { Country } from './types/country.type';
import { Currency } from './types/currency.type';
import { DeliveryMethod } from './types/deliveryMethod.type';
import { NewCardRequest } from './types/newCardRequest.type';
import { PaginatedResponse } from './types/paginatedResponse.type';
import { RevolupayOrderData } from './types/revolupayOrderData.type';
import { Settings } from './types/settings.type';
import { Topup } from './types/topup.type';
import { TopupStatus } from './types/topupStatus.enum';
import { Transaction } from './types/transaction.type';
import { UserData } from './types/userData.type';

export class Api {

  constructor(
    private readonly config: Config,
    private readonly auth: Auth,
  ) { }

  async login(
    username: string,
    password: string,
  ): Promise<{
    success: boolean;
    token?: string;
    valid_kyc: boolean;
    valid_profile: boolean;
    valid_credentials: boolean;
  }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/login',
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          prefer: 'code=201, example=example-1'
        },
        body: JSON.stringify({ username, password })
      }
    );
    if (response.ok) {
      const jsonResponse = await response.json();
      const token = jsonResponse.token;
      if (jsonResponse.valid_kyc && jsonResponse.valid_profile) {
        return {
          success: true,
          token,
          valid_kyc: true,
          valid_profile: true,
          valid_credentials: true
        };
      } else {
        return {
          success: false,
          valid_kyc: jsonResponse.valid_kyc,
          valid_profile: jsonResponse.valid_profile,
          valid_credentials: true
        };
      }
    } else {
      return {
        success: false,
        valid_kyc: false,
        valid_profile: false,
        valid_credentials: false
      };
    }
  }


  async getCardRequests(): Promise<CardRequest[]> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/card/requests`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          prefer: 'code=200, example=example-2'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch card requests');
    }
    return (await response.json()).requests;
  }

  async getCountries(): Promise<Country[]> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/countries`,
      {
        headers: {
          prefer: 'code=200, example=example-1'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not fetch countries');
    }
    return (await response.json()).countries;
  }

  async getCurrencies(): Promise<Currency[]> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/currencies`,
      {
        headers: {
          prefer: 'code=200, example=example-1'
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch currencies');
    }
    return (await response.json()).currencies;
  }

  async getPaymentCurrencies(): Promise<Currency[]> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/payment-currencies`,
      {
        headers: {
          prefer: 'code=200, example=example-1'
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch currencies');
    }
    return (await response.json()).currencies;
  }

  async getDeliveryMethods(): Promise<DeliveryMethod[]> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/delivery_methods`,
      {
        headers: {
          prefer: 'code=200, example=example-1'
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not fetch delivery methods');
    }
    return (await response.json()).delivery_methods;
  }


  async getUserData(): Promise<UserData> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/me`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          prefer: 'code=200, example=example-1'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not get user data');
    }
    return response.json();
  }

  async getSettings(): Promise<Settings> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/settings`,
      {
        headers: {
          prefer: 'code=200, example=example-1'
        }
      }
    );
    if (!response.ok) {
      throw new Error('Could not get settings');
    }
    return (await response.json()).settings;
  }

  async addCardRequest(newCardRequest: NewCardRequest): Promise<RevolupayOrderData> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/card/requests`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          'content-type': 'application/json',
          prefer: 'code=201, example=example-1'

        },
        body: JSON.stringify(newCardRequest)
      }
    )
    if (!response.ok) {
      throw new Error('Could not create card request');
    }
    return response.json();

  }

  async hasRevoluPAYOrderBeenPaid(orderId: string): Promise<boolean> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/revolupay_orders/${orderId}`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          prefer: 'code=200, example=example-1'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not get payment data');
    }
    return (await response.json()).paid_at !== null;
  }

  async getCards(limit: number = 50, offset: number = 0): Promise<PaginatedResponse<Card>> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    const response = await fetch(
      `${this.config.API_BASE_URL}/cards?${params.toString()}`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          prefer: 'code=200, example=example-1'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not get cards');
    }
    const json = await response.json();
    return {
      count: json.count,
      data: json.cards
    }
  }

  async getCardBalance(cardId: string): Promise<CardBalance> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/cards/${cardId}/balance`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          prefer: 'code=200, example=example-1'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not get card balance');
    }
    return response.json();
  }

  async requestPin(cardId: string) {
    const response = await fetch(
      `${this.config.API_BASE_URL}/cards/${cardId}/pin`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          prefer: 'code=200'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not request card pin');
    }
  }

  async blockCard(cardId: string) {
    const response = await fetch(
      `${this.config.API_BASE_URL}/cards/${cardId}/block`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          prefer: 'code=200'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not block card.');
    }
  }

  async getCardLimits(cardId: string): Promise<CardLimit[]> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/cards/${cardId}/limits`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          prefer: 'code=200, example=example-1'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not get card limits');
    }
    return (await response.json()).limits;
  }

  async getCardTopups(
    cardId: string,
    limit: number = 50,
    offset: number = 0,
    status?: TopupStatus
  ): Promise<PaginatedResponse<Topup>> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    params.append('order', 'DESC');
    if (status) {
      params.append('status', status);
    }
    const response = await fetch(
      `${this.config.API_BASE_URL}/cards/${cardId}/topups?${params.toString()}`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          prefer: 'code=200, example=example-3'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not get card topups');
    }
    const json = await response.json();
    return {
      count: json.count,
      data: json.topups
    }
  }

  async addTopup(currencyId: number, cardId: number, amount: number): Promise<RevolupayOrderData> {
    const response = await fetch(
      `${this.config.API_BASE_URL}/topups`,
      {
        method: 'POST',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          'content-type': 'application/json',
          prefer: 'code=201, example=example-1'
        },
        body: JSON.stringify({
          currency_id: currencyId,
          card_id: cardId,
          amount
        })
      }
    )
    if (!response.ok) {
      throw new Error('Could not add card topup');
    }
    return response.json();
  }

  async getCardTransactions(
    cardId: string,
    limit: number = 50,
    offset: number = 0,
    createdBefore?: string,
    createdAfter?: string
  ): Promise<PaginatedResponse<Transaction>> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    params.append('order', 'DESC');
    if (createdBefore) {
      params.append('created_before', createdBefore);
    }
    if (createdAfter) {
      params.append('created_after', createdAfter);
    }
    const response = await fetch(
      `${this.config.API_BASE_URL}/cards/${cardId}/transactions?${params.toString()}`,
      {
        method: 'GET',
        headers: {
          authorization: `Bearer ${this.auth.getAccessToken()}`,
          prefer: 'code=200, example=example-1'
        }
      }
    )
    if (!response.ok) {
      throw new Error('Could not get card transactions');
    }
    const json = await response.json();
    return {
      count: json.count,
      data: json.transactions
    }

  }


}
