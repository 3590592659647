import React from 'react';
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Card } from '../services/types/card.type';
import utils from '../services/utils.service';
import { useApi } from '../context';
import { Loading } from '../components/loading';
import { Card as CardComponent } from '../components/card';
import { Redirect, useHistory } from 'react-router-dom';
import { ErrorDialog } from '../components/errorDialog';
import { Config } from '../config';

const config = new Config();
export const CardSelector = () => {
  const { t } = useTranslation('dashboard');

  const api = useApi();
  const history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [cards, setCards] = React.useState<Card[]>([]);
  const [error, setError] = React.useState(false);

  const loadCards = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setCards((await api.getCards()).data);
    }, (e) => {
      if (e) {
        setError(true);
      }
      setLoading(false);
    });
  }

  React.useEffect(() => {
    loadCards();
  }, []);

  if (loading) {
    return <Loading />
  }

  if (!config.ALLOW_MULTIPLE_CARDS && cards.length > 0) {
    return <Redirect to={`/cards/${cards[0].id}`} />
  }

  return (
    <>
      <ErrorDialog
        open={error}
        title={t('LOADING_ERROR_TITLE')}
        message={t('LOADING_ERROR_MESSAGE')}
        onClose={() => {
          setError(false);
          loadCards();
        }}
      />
      <Grid container alignContent='flex-start' spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' align='center'>
            {t('CARD_SELECTION_TITLE')}
          </Typography>
        </Grid>
        {cards.map(c => (
          <Grid key={c.id} item xs={12} sm={6}>
            <CardComponent card={c} onClick={() => { history.push(`/cards/${c.id}`) }} />
          </Grid>
        ))}
      </Grid>

    </>
  )

}