import React from 'react';
import {
    Button,
    createStyles,
    Grid,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { useApi } from '../context'
import { useTranslation } from 'react-i18next';
import utils from '../services/utils.service';
import { ErrorDialog } from '../components/errorDialog';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '../components/loading';
import { Card } from '../services/types/card.type';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { Transaction } from '../services/types/transaction.type';
import NewCardImg from '../assets/img/newcard.svg';
import { TransactionRow } from './transactionRow';
import { LoadingButton } from '../components/loadingButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            marginTop: theme.spacing(2),
            width: 240,
            maxWidth: '100%'
        },
        filterContainer:{
            marginBottom:theme.spacing(1),
        },
        option: {
            '& > span': {
                marginRight: 10,
                fontSize: 18,
            },
        },
    }),
);

type FilterOption = {
    created_before?: string;
    created_after: string;
    label: string;
}



export const Transactions = () => {
    const { cardId } = useParams<{ cardId: string }>();
    const { t, i18n } = useTranslation('card');
    moment.locale(i18n.language);

    const api = useApi();
    const classes = useStyles();
    const history = useHistory();

    const limit = 25;

    const defaultFilterOption: FilterOption = {
        created_after: moment().subtract(30, 'days').toISOString(),
        label: t('LAST_30_DAYS')
    }

    const [card, setCard] = React.useState<Card | undefined>();
    const [loadingCards, setLoadingCards] = React.useState(true);
    const [loadingTransactions, setLoadingTransactions] = React.useState(true);
    const [transactions, setTransactions] = React.useState<Transaction[]>([]);
    const [selectedFilterOption, setSelectedFilterOption] = React.useState<FilterOption>(defaultFilterOption);
    const [count, setCount] = React.useState(0);
    const [offset, setOffset] = React.useState(0);

    const [error, setError] = React.useState(false);


    const getFilterOptions = () => {
        const start = moment('2022-06-01');
        const end = moment();
        const options: FilterOption[] = [];
        while (start.isBefore(end)) {
            options.push({
                created_before: start.clone().endOf('month').toISOString(),
                created_after: start.clone().startOf('month').toISOString(),
                label: start.format('MMMM YYYY')
            });
            start.add(1, 'month');
        }
        return [
            defaultFilterOption,
            ...options
        ]
    }

    const loadCards = () => {
        utils.runAsync(async () => {
            setLoadingCards(true);
            const cards = (await api.getCards()).data;
            const cardFindResult = cards.find(c => c.id === parseInt(cardId, 10));
            if (!cardFindResult) {
                history.push('/dashbaord');
            } else {
                setCard(cardFindResult);
            }
        }, (e) => {
            setLoadingCards(false);
            if (e) {
                setError(true);
            }
        });
    }

    const loadTransactions = (reset: boolean = false) => {
        utils.runAsync(async () => {
            setLoadingTransactions(true);
            let applyOffset = offset;
            if (reset) {
                applyOffset = 0;
                setTransactions([]);
            }
            const response = await api.getCardTransactions(
                cardId,
                limit,
                applyOffset,
                selectedFilterOption?.created_before,
                selectedFilterOption?.created_after
            );
            setOffset(applyOffset + limit)
            setCount(response.count);
            if (reset) {
                setTransactions(response.data);
            } else {
                setTransactions([...transactions, ...response.data]);
            }
        }, (e) => {
            setLoadingTransactions(false);
        });
    }

    React.useEffect(() => {
        loadCards();
        loadTransactions();
    }, []);

    React.useEffect(() => {
        loadTransactions(true);
    }, [selectedFilterOption]);

    if (loadingCards) {
        return <Loading />
    }

    return (
        <>
            <ErrorDialog
                open={error}
                title={t('LOADING_ERROR_TITLE')}
                message={t('LOADING_ERROR_MESSAGE')}
                onClose={() => {
                    setError(false);
                    loadCards();
                }}
            />
            <Grid container alignContent='flex-start' spacing={2}>
                <Grid item xs={12}>
                    <Button
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => {
                            history.push(`/cards/${cardId}`)
                        }} >
                        {t('BACK_TO_CARD_DETAILS')}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h4' gutterBottom>
                        {t('CARD_TRANSACTION_LIST_TITLE', { cardMp: card!.mp })}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.filterContainer}>
                    <Autocomplete
                        onChange={(event, value) => {
                            setSelectedFilterOption(value);
                        }}
                        getOptionSelected={(option, value) => option.label === value.label}
                        value={selectedFilterOption}
                        options={getFilterOptions()}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        disableClearable
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('FILTER_TRANSACTIONS')}
                                variant='outlined'
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'chrome-off',
                                }}
                            />
                        )}
                    />
                </Grid>
                {(loadingTransactions && transactions.length === 0) &&
                    <Grid item xs={12}>
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                        <Skeleton height={100} />
                    </Grid>
                }
                {(!loadingTransactions && transactions.length === 0) &&
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container justifyContent='center'>
                                    <Grid item>
                                        <img className={classes.image} src={NewCardImg} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align='center'>
                                    {t('DETAILED_TRANSACTIONS_PLACEHOLDER_DESCRIPTION')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Grid container>
                        {(transactions.map(t => (
                            <TransactionRow key={t.id} transaction={t} />
                        )))}
                    </Grid>
                </Grid>
                {(transactions.length > 0 && offset < count) &&
                    <Grid item xs={12}>
                        <Grid container justifyContent='center'>
                            <Grid item>
                                <LoadingButton
                                    loading={loadingTransactions}
                                    onClick={() => {
                                        loadTransactions();
                                    }}>
                                    {t(`LOAD_MORE`)}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid >
        </>
    )
}