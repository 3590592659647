{
  "LOADING_ERROR_TITLE": "Error!",
  "LOADING_ERROR_MESSAGE": "We couldn't obtain the requested data. Please try again.",
  "ALREADY_ORDERED_CARD_ERROR_TITLE": "You already have ordered a card!",
  "ALREADY_ORDERED_CARD_ERROR_MESSAGE": "Currently only one RevoluCARD Instant can be ordered. We will support multiple RevoluCARD Instant in the near feature. We'll keep you posted!",
  "ORDER_NEW_CARD": "Order a RevoluCARD Instant",
  "DESTINATION": "Destination",
  "DELIVERY": "Shipping method",
  "CHECKOUT": "Checkout",
  "BACK": "BACK",
  "NEXT": "NEXT",
  "CANCEL": "CANCEL",
  "TO_ME": "To me",
  "TO_SOMEONE_ELSE": "To someone else",
  "NAME": "Name",
  "LAST_NAME": "Last name",
  "STATE_OR_PROVINCE": "State or province",
  "ADDRESS": "Address",
  "POSTAL_CODE": "Postal code",
  "CITY": "City",
  "CONTACT_DATA": "Contact details",
  "PHONE": "Phone",
  "COUNTRY_OF_RESIDENCE": "Country",
  "ADDRESS_TYPE": "To whom should we send the card?",
  "ADDRESS_TYPE_DESCRIPTION": "Even if you select the card to be shipped to someone else, it will still be linked to your RevoluPay Account and you will be the one managing the card.",
  "INVALID_PHONE": "Invalid phone number. Please enter the phone with the international prefix. For example: +10123456789.",
  "REQUIRED": "This field is mandatory.",
  "PAYMENT_CURRENCY_DESCRIPTION": "Please select how many cards you would like to receive with this order and your prefered currency for the order payment.",
  "CARD_AMOUNT": "How many cards?",
  "CARD_NUMBER": "Card quantity",
  "PAYMENT_CURRENCY": "Payment currency",
  "SHIPPING_METHOD_TITLE": "Select a shipping method",
  "SUMMARY": "Order summary",
  "NO_DELIVERY_METHODS_AVAILABLE": "Currently shipping is not available to the selected country. Please try again in a few days.",
  "CARD_ISSUE_FEE": "Card issue fee",
  "SHIPPING_FEE": "Shipping cost",
  "TOTAL": "Total due",
  "FINISH": "Submit",
  "SUBMIT_ERROR_TITLE": "Error!",
  "SUBMIT_ERROR_MESSAGE": "We couldn't place your card order. Please try again later. If the issue persists please open a ticket throught the Help Center.",
  "CHECKOUT_INTRO": "Please verify the order details and once ready press on submit.",
  "DELIVERY_ADDRESS": "Delivery Address",
  "DELIVERY_METHOD": "Shipping method",
  "PAYMENT": "Order summary",
  "TERMS_CONDITIONS": "<0>I accept the <1>Terms & Conditions</1>.</0>",
  "MUST_ACCEPT_TERMS_ERROR_TITLE": "Terms & Conditions not accepted",
  "MUST_ACCEPT_TERMS_ERROR_DESCRIPTION": "In order to request a card you need to accept the terms & conditions.",
  "MAX_LENGTH": "The field content is too long.",
  "NO_ONLINE_PAYMENTS_WARNING": "Warning: RevoluCARD Instant cards do not support online payments."
}