import React from 'react';
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useApi } from '../context'
import { useTranslation } from 'react-i18next';
import utils from '../services/utils.service';
import { ErrorDialog } from '../components/errorDialog';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '../components/loading';
import { Autocomplete } from '@material-ui/lab';
import { Currency } from '../services/types/currency.type';
import { CardLimit } from '../services/types/cardLimit.type';
import { Settings } from '../services/types/settings.type';
import { LoadingButton } from '../components/loadingButton';
import { Card } from '../services/types/card.type';
import { ConfirmDialog } from '../components/confirmDialog';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';

type Form = {
  amount: string;
  paymentCurrency: Currency | null;
}
type FormErrors = {
  amount: string | undefined;
  paymentCurrency: string | undefined;
}

type FormWithErrors = {
  form: Form;
  errors: FormErrors;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      marginTop: theme.spacing(2)
    },
    formContainer:{
      padding:theme.spacing(2)
    },
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  }),
);

export const Topup = () => {
  const { cardId } = useParams<{ cardId: string }>();
  const { t, i18n } = useTranslation('topup');
  moment.locale(i18n.language);

  const api = useApi();
  const classes = useStyles();
  const history = useHistory();

  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [limits, setLimits] = React.useState<CardLimit[]>([]);
  const [settings, setSettings] = React.useState<Settings | undefined>();
  const [card, setCard] = React.useState<Card | undefined>();

  const [submitting, setSubmitting] = React.useState(false);
  const [loadingCards, setLoadingCards] = React.useState(true);
  const [loadingCurrencies, setLoadingCurrencies] = React.useState(true);
  const [loadingLimits, setLoadingLimits] = React.useState(true);
  const [loadingSettings, setLoadingSettings] = React.useState(true);

  const [confirmTopup, setConfirmTopup] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);
  const [error, setError] = React.useState(false);

  const emptyFormErrors = {
    amount: undefined,
    paymentCurrency: undefined,
  };

  const emptyForm = {
    amount: '250',
    paymentCurrency: null
  }

  const [formWithErrors, setFormWithErrors] = React.useReducer((state: FormWithErrors, action: { form?: Partial<Form>, errors?: Partial<FormErrors> }) => {
    const newFormWithErrors: FormWithErrors = { ...state };
    if (action.form) {
      newFormWithErrors.form = {
        ...state.form,
        ...action.form
      }
    }
    if (action.errors) {
      newFormWithErrors.errors = {
        ...state.errors,
        ...action.errors
      }
    }
    return newFormWithErrors;
  }, { form: emptyForm, errors: emptyFormErrors });


  const form = formWithErrors.form;
  const formErrors = formWithErrors.errors;

  const validate = () => {
    const errors = Object.assign({}, emptyFormErrors);
    if (!(parseFloat(form.amount) > 0)) {
      errors.amount = t('INVALID_AMOUNT');
    }
    const topupAmount = parseFloat(form.amount);


    for (const limit of limits) {
      if (topupAmount > limit.remaining_amount) {
        errors.amount = t('LIMIT_EXCEEDED', { frequency: t(limit.frequency), remainingAmount: utils.currencyFormat(limit.remaining_amount) })
        break;
      }
    }

    if (topupAmount > settings!.topup_amount) {
      errors.amount = t('MAX_SINGLE_TOPUP_AMOUNT_EXCEEDED', { maxAmount: utils.currencyFormat(settings!.topup_amount) });
    }

    if (form.paymentCurrency === null) {
      errors.paymentCurrency = t('REQUIRED');
    }
    const isValid = Object.keys(errors).find(k => (errors as any)[k] !== undefined) === undefined;
    if (!isValid) {
      setFormWithErrors({ errors });
    }
    return isValid;
  }

  const loadCurrencies = () => {
    utils.runAsync(async () => {
      setLoadingCurrencies(true);
      const currencies = await api.getPaymentCurrencies();
      setCurrencies(currencies);
      const eurCurrency = currencies.find(c => c.iso === 'EUR');
      if (eurCurrency) {
        setFormWithErrors({ form: { paymentCurrency: eurCurrency } });
      }
    }, (e) => {
      setLoadingCurrencies(false);
      if (e) {
        setLoadingError(true);
      }
    });
  }

  const loadCards = () => {
    utils.runAsync(async () => {
      setLoadingCards(true);
      const cards = (await api.getCards()).data;
      const cardFindResult = cards.find(c => c.id === parseInt(cardId, 10));
      if (!cardFindResult) {
        history.push('/dashbaord');
      } else {
        setCard(cardFindResult);
      }
    }, (e) => {
      setLoadingCards(false);
      if (e) {
        setLoadingError(true);
      }
    });
  }

  const loadLimits = () => {
    utils.runAsync(async () => {
      setLoadingLimits(true);
      setLimits(await api.getCardLimits(cardId));
    }, (e) => {
      setLoadingLimits(false);
      if (e) {
        setLoadingError(true);
      }
    });
  }

  const loadSettings = () => {
    utils.runAsync(async () => {
      setLoadingSettings(true);
      const tsettings = await api.getSettings();
      setFormWithErrors({ form: { amount: tsettings.topup_amount.toString(10) } })
      setSettings(tsettings);
    }, (e) => {
      setLoadingSettings(false);
      if (e) {
        setLoadingError(true);
      }
    });
  }

  React.useEffect(() => {
    loadLimits();
    loadCurrencies();
    loadSettings();
    loadCards();
  }, []);


  const submit = () => {
    if (validate()) {
      utils.runAsync(async () => {
        setSubmitting(true);
        const paymentData = await api.addTopup(
          form.paymentCurrency!.id,
          card!.id,
          parseFloat(form.amount)
        );
        history.push(`/payments/check?id=${paymentData.revolupay_order_id}&code=${paymentData.revolupay_order_code}`)

      }, (e) => {
        if (e) {
          setSubmitting(false);
          setError(true);
        }
      });

    }
  }

  const getFee = () => {
    let fee = 0;
    if (settings?.topup_variable_fee) {
      fee += getAmountInPaymentCurrency() * settings.topup_variable_fee / 100;
    }
    if (settings?.topup_fixed_fee) {
      fee += settings.topup_fixed_fee / form.paymentCurrency!.rate;
    }
    return fee;
  }

  const getFormattedAmount = (value?: string) => {
    if (value && parseFloat(value) > 0) {
      return utils.round(parseFloat(value.replace(',', '.'))).toString(10)
    } else return '100';
  }

  const getTotalDue = () => {
    return getAmountInPaymentCurrency() + getFee();
  }

  const getAmountInPaymentCurrency = () => {
    return parseFloat(form.amount) / form.paymentCurrency!.rate;
  }

  const getNextApplicationDate = () => {
    const topupMoment = moment();
    const theoricalApplicationDate = topupMoment.add(1, 'day').startOf('day');
    if (theoricalApplicationDate.day() === 6 || theoricalApplicationDate.day() === 0) {
      theoricalApplicationDate.add(1, 'weeks').startOf('isoWeek');
    }
    return theoricalApplicationDate.format('lll');
  }


  if (loadingCurrencies || loadingLimits || loadingSettings || loadingCards) {
    return <Loading />
  }


  return (
    <>
      <ErrorDialog
        open={loadingError}
        title={t('LOADING_ERROR_TITLE')}
        message={t('LOADING_ERROR_MESSAGE')}
        onClose={() => {
          setLoadingError(false);
          loadSettings();
          loadCurrencies();
          loadLimits();
          loadCards();
        }}
      />
      <ErrorDialog
        open={error}
        title={t('SUBMIT_ERROR_TITLE')}
        message={t('SUBMIT_ERROR_MESSAGE')}
        onClose={() => {
          setError(false);
        }}
      />
      <ConfirmDialog
        open={confirmTopup}
        title={t('CONFIRM_TOPUP_TITLE')}
        message={t('CONFIRM_TOPUP_MESSAGE', { applicationDate: getNextApplicationDate(), cardMp: card!.mp, amount: utils.currencyFormat(form.amount) })}
        onCancel={() => {
          setConfirmTopup(false);
        }}
        onConfirm={() => {
          setConfirmTopup(false);
          submit();
        }}
      />
      <Grid container alignContent='flex-start'>
        <Grid item xs={12}>
          <Button
            startIcon={<ArrowBackIosIcon />}
            onClick={() => {
              history.push(`/cards/${cardId}`)
            }} >
            {t('BACK_TO_CARD_DETAILS')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h4' gutterBottom>
            {t('TOPUP_CARD', { cardMp: card!.mp })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.formContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='body1'>
                  {t('TOPUP_DESCRIPTION')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth={true}
                  label={t('AMOUNT')}
                  error={formErrors.amount !== undefined}
                  helperText={formErrors.amount}
                  InputProps={{
                    endAdornment: 'EUR'
                  }}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  variant='outlined'
                  value={form.amount}
                  onBlur={(event) => {
                    setFormWithErrors({ form: { amount: getFormattedAmount(event.target.value) } })
                  }}
                  onChange={(event) => {
                    setFormWithErrors({ form: { amount: event.target.value }, errors: { amount: undefined } });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  onChange={(event, value) => {
                    setFormWithErrors({ form: { paymentCurrency: value }, errors: { paymentCurrency: undefined } });
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  value={form.paymentCurrency!}
                  options={currencies}
                  classes={{
                    option: classes.option,
                  }}
                  disableClearable
                  autoHighlight
                  getOptionLabel={(option) => option.iso}
                  renderOption={(option) => option.iso}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('PAYMENT_CURRENCY')}
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'chrome-off',
                      }}
                      error={formErrors.paymentCurrency !== undefined}
                      helperText={formErrors.paymentCurrency}
                    />
                  )}
                />
              </Grid>

              {form.paymentCurrency?.iso !== 'EUR' &&
                <>
                  <Grid item xs={8}>
                    <Typography variant='subtitle2'>
                      {t('AMOUNT_IN_PAYMENT_CURRENCY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='subtitle1' align='right'>
                      {`${utils.currencyFormat(getAmountInPaymentCurrency())} ${form.paymentCurrency!.iso}`}
                    </Typography>
                  </Grid>
                </>
              }
              {parseFloat(form.amount) > 0 &&
                <>
                  <Grid item xs={8}>
                    <Typography variant='subtitle2'>
                      {t('FEE')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='subtitle1' align='right'>
                      {`${utils.currencyFormat(getFee())} ${form.paymentCurrency!.iso}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant='h5'>
                      {t('TOTAL')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant='h6' align='right'>
                      {`${utils.currencyFormat(getTotalDue())} ${form.paymentCurrency!.iso}`}
                    </Typography>
                  </Grid>
                </>
              }
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Grid container spacing={2} justifyContent='flex-end'>
                <Grid item>
                  <Button
                    onClick={() => history.push(`/cards/${cardId}`)}>
                    {t('CANCEL')}
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    variant='contained'
                    color='primary'
                    loading={submitting}
                    onClick={() => {
                      if (validate()) {
                        setConfirmTopup(true);
                      }
                    }}>
                    {t('SUBMIT')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid >
    </>
  )
}