{
  "PAYMENT_TITLE": "Pago",
  "PAYMENT_DESCRIPTION": "Escanea el QR con la app RevoluPAY o pulsa sobre el enlace que aparece debajo del QR (si estás operando desde el móvil).",
  "PAY_IN_APP": "Pagar con RevoluPAY",
  "PAGE_WILL_REFRESH": "La página se refrescará automáticamente cuando se haya completado el pago.",
  "PAYMENT_SUCCESS_TITLE": "Pago completado",
  "PAYMENT_SUCCESS_DESCRIPTION": "Enhorabuena, tu pago ha sido procesado con éxito. Revisa tu correo electrónico para obtener información adicional.",
  "PAYMENT_ERROR_TITLE": "Payment error",
  "PAYMENT_ERROR_DESCRIPTION": "Ha ocurrido un error durante el proceso de pago. Vuelve a intentarlo.",
  "DASHBOARD": "INICIO"
}