import { i18n } from 'i18next';
import { Config } from '../config';
import { LocalizedString } from './types/localizedString.type';

const config = new Config();
const utils = {
  round: (aNumber: number) => Math.round((aNumber + Number.EPSILON) * 100) / 100,
  runAsync: (runnable: () => Promise<void>, onDone?: (error?: any) => void) => {
    runnable().then(() => {
      if (onDone) {
        onDone();
      }
    }).catch(e => {
      if (e) {
        console.error(e);
      }
      if (onDone) {
        onDone(e);
      }
    });
  },
  currencyFormat: (aNumber: number, fractionDigits: number = 2) => new Intl.NumberFormat(
    ['es-ES', 'en-GB', 'en-US'],
    { style: 'decimal', minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
  ).format(aNumber),

  important: (value: any) => {
    return (value + ' !important');
  },

  getLocalizedText: (i18n: i18n, value: LocalizedString) => {
    if (value && value[i18n.language]) {
      return value[i18n.language];
    } else if (value && value['en']) {
      return value['en'];
    }else if(value && value['es']){
      return value['es'];
    }
    return value?.toString();
  },

  getOrDefault: (value?: string, def = ''): string => {
    if (value) return value;
    return def;
  },
}

export default utils
