[
 {
   "ISO3": "AFG",
   "ISO2": "AF",
   "ISONUMERIC": 4,
   "DIAL": "93",
   "NAME": "Afghanistan"
 },
 {
   "ISO3": "ALB",
   "ISO2": "AL",
   "ISONUMERIC": 8,
   "DIAL": "355",
   "NAME": "Albania"
 },
 {
   "ISO3": "ATA",
   "ISO2": "AQ",
   "ISONUMERIC": 10,
   "DIAL": "672",
   "NAME": "Antarctica"
 },
 {
   "ISO3": "DZA",
   "ISO2": "DZ",
   "ISONUMERIC": 12,
   "DIAL": "213",
   "NAME": "Algeria"
 },
 {
   "ISO3": "ASM",
   "ISO2": "AS",
   "ISONUMERIC": 16,
   "DIAL": "1-684",
   "NAME": "American Samoa"
 },
 {
   "ISO3": "AND",
   "ISO2": "AD",
   "ISONUMERIC": 20,
   "DIAL": "376",
   "NAME": "Andorra"
 },
 {
   "ISO3": "AGO",
   "ISO2": "AO",
   "ISONUMERIC": 24,
   "DIAL": "244",
   "NAME": "Angola"
 },
 {
   "ISO3": "ATG",
   "ISO2": "AG",
   "ISONUMERIC": 28,
   "DIAL": "1-268",
   "NAME": "Antigua & Barbuda"
 },
 {
   "ISO3": "AZE",
   "ISO2": "AZ",
   "ISONUMERIC": 31,
   "DIAL": "994",
   "NAME": "Azerbaijan"
 },
 {
   "ISO3": "ARG",
   "ISO2": "AR",
   "ISONUMERIC": 32,
   "DIAL": "54",
   "NAME": "Argentina"
 },
 {
   "ISO3": "AUS",
   "ISO2": "AU",
   "ISONUMERIC": 36,
   "DIAL": "61",
   "NAME": "Australia"
 },
 {
   "ISO3": "AUT",
   "ISO2": "AT",
   "ISONUMERIC": 40,
   "DIAL": "43",
   "NAME": "Austria"
 },
 {
   "ISO3": "BHS",
   "ISO2": "BS",
   "ISONUMERIC": 44,
   "DIAL": "1-242",
   "NAME": "Bahamas"
 },
 {
   "ISO3": "BHR",
   "ISO2": "BH",
   "ISONUMERIC": 48,
   "DIAL": "973",
   "NAME": "Bahrain"
 },
 {
   "ISO3": "BGD",
   "ISO2": "BD",
   "ISONUMERIC": 50,
   "DIAL": "880",
   "NAME": "Bangladesh"
 },
 {
   "ISO3": "ARM",
   "ISO2": "AM",
   "ISONUMERIC": 51,
   "DIAL": "374",
   "NAME": "Armenia"
 },
 {
   "ISO3": "BRB",
   "ISO2": "BB",
   "ISONUMERIC": 52,
   "DIAL": "1-246",
   "NAME": "Barbados"
 },
 {
   "ISO3": "BEL",
   "ISO2": "BE",
   "ISONUMERIC": 56,
   "DIAL": "32",
   "NAME": "Belgium"
 },
 {
   "ISO3": "BMU",
   "ISO2": "BM",
   "ISONUMERIC": 60,
   "DIAL": "1-441",
   "NAME": "Bermuda"
 },
 {
   "ISO3": "BTN",
   "ISO2": "BT",
   "ISONUMERIC": 64,
   "DIAL": "975",
   "NAME": "Bhutan"
 },
 {
   "ISO3": "BOL",
   "ISO2": "BO",
   "ISONUMERIC": 68,
   "DIAL": "591",
   "NAME": "Bolivia"
 },
 {
   "ISO3": "BIH",
   "ISO2": "BA",
   "ISONUMERIC": 70,
   "DIAL": "387",
   "NAME": "Bosnia"
 },
 {
   "ISO3": "BWA",
   "ISO2": "BW",
   "ISONUMERIC": 72,
   "DIAL": "267",
   "NAME": "Botswana"
 },
 {
   "ISO3": "BVT",
   "ISO2": "BV",
   "ISONUMERIC": 74,
   "DIAL": "47",
   "NAME": "Bouvet Island"
 },
 {
   "ISO3": "BRA",
   "ISO2": "BR",
   "ISONUMERIC": 76,
   "DIAL": "55",
   "NAME": "Brazil"
 },
 {
   "ISO3": "BLZ",
   "ISO2": "BZ",
   "ISONUMERIC": 84,
   "DIAL": "501",
   "NAME": "Belize"
 },
 {
   "ISO3": "IOT",
   "ISO2": "IO",
   "ISONUMERIC": 86,
   "DIAL": "246",
   "NAME": "British Indian Ocean Territory"
 },
 {
   "ISO3": "SLB",
   "ISO2": "SB",
   "ISONUMERIC": 90,
   "DIAL": "677",
   "NAME": "Solomon Islands"
 },
 {
   "ISO3": "VGB",
   "ISO2": "VG",
   "ISONUMERIC": 92,
   "DIAL": "1-284",
   "NAME": "British Virgin Islands"
 },
 {
   "ISO3": "BRN",
   "ISO2": "BN",
   "ISONUMERIC": 96,
   "DIAL": "673",
   "NAME": "Brunei"
 },
 {
   "ISO3": "BGR",
   "ISO2": "BG",
   "ISONUMERIC": 100,
   "DIAL": "359",
   "NAME": "Bulgaria"
 },
 {
   "ISO3": "MMR",
   "ISO2": "MM",
   "ISONUMERIC": 104,
   "DIAL": "95",
   "NAME": "Myanmar"
 },
 {
   "ISO3": "BDI",
   "ISO2": "BI",
   "ISONUMERIC": 108,
   "DIAL": "257",
   "NAME": "Burundi"
 },
 {
   "ISO3": "BLR",
   "ISO2": "BY",
   "ISONUMERIC": 112,
   "DIAL": "375",
   "NAME": "Belarus"
 },
 {
   "ISO3": "KHM",
   "ISO2": "KH",
   "ISONUMERIC": 116,
   "DIAL": "855",
   "NAME": "Cambodia"
 },
 {
   "ISO3": "CMR",
   "ISO2": "CM",
   "ISONUMERIC": 120,
   "DIAL": "237",
   "NAME": "Cameroon"
 },
 {
   "ISO3": "CAN",
   "ISO2": "CA",
   "ISONUMERIC": 124,
   "DIAL": "1",
   "NAME": "Canada"
 },
 {
   "ISO3": "CPV",
   "ISO2": "CV",
   "ISONUMERIC": 132,
   "DIAL": "238",
   "NAME": "Cape Verde"
 },
 {
   "ISO3": "CYM",
   "ISO2": "KY",
   "ISONUMERIC": 136,
   "DIAL": "1-345",
   "NAME": "Cayman Islands"
 },
 {
   "ISO3": "CAF",
   "ISO2": "CF",
   "ISONUMERIC": 140,
   "DIAL": "236",
   "NAME": "Central African Republic"
 },
 {
   "ISO3": "LKA",
   "ISO2": "LK",
   "ISONUMERIC": 144,
   "DIAL": "94",
   "NAME": "Sri Lanka"
 },
 {
   "ISO3": "TCD",
   "ISO2": "TD",
   "ISONUMERIC": 148,
   "DIAL": "235",
   "NAME": "Chad"
 },
 {
   "ISO3": "CHL",
   "ISO2": "CL",
   "ISONUMERIC": 152,
   "DIAL": "56",
   "NAME": "Chile"
 },
 {
   "ISO3": "CHN",
   "ISO2": "CN",
   "ISONUMERIC": 156,
   "DIAL": "86",
   "NAME": "China"
 },
 {
   "ISO3": "TWN",
   "ISO2": "TW",
   "ISONUMERIC": 158,
   "DIAL": "886",
   "NAME": "Taiwan"
 },
 {
   "ISO3": "CXR",
   "ISO2": "CX",
   "ISONUMERIC": 162,
   "DIAL": "61",
   "NAME": "Christmas Island"
 },
 {
   "ISO3": "CCK",
   "ISO2": "CC",
   "ISONUMERIC": 166,
   "DIAL": "61",
   "NAME": "Cocos (Keeling) Islands"
 },
 {
   "ISO3": "COL",
   "ISO2": "CO",
   "ISONUMERIC": 170,
   "DIAL": "57",
   "NAME": "Colombia"
 },
 {
   "ISO3": "COM",
   "ISO2": "KM",
   "ISONUMERIC": 174,
   "DIAL": "269",
   "NAME": "Comoros"
 },
 {
   "ISO3": "MYT",
   "ISO2": "YT",
   "ISONUMERIC": 175,
   "DIAL": "262",
   "NAME": "Mayotte"
 },
 {
   "ISO3": "COG",
   "ISO2": "CG",
   "ISONUMERIC": 178,
   "DIAL": "242",
   "NAME": "Congo - Brazzaville"
 },
 {
   "ISO3": "COD",
   "ISO2": "CD",
   "ISONUMERIC": 180,
   "DIAL": "243",
   "NAME": "Congo - Kinshasa"
 },
 {
   "ISO3": "COK",
   "ISO2": "CK",
   "ISONUMERIC": 184,
   "DIAL": "682",
   "NAME": "Cook Islands"
 },
 {
   "ISO3": "CRI",
   "ISO2": "CR",
   "ISONUMERIC": 188,
   "DIAL": "506",
   "NAME": "Costa Rica"
 },
 {
   "ISO3": "HRV",
   "ISO2": "HR",
   "ISONUMERIC": 191,
   "DIAL": "385",
   "NAME": "Croatia"
 },
 {
   "ISO3": "CUB",
   "ISO2": "CU",
   "ISONUMERIC": 192,
   "DIAL": "53",
   "NAME": "Cuba"
 },
 {
   "ISO3": "CYP",
   "ISO2": "CY",
   "ISONUMERIC": 196,
   "DIAL": "357",
   "NAME": "Cyprus"
 },
 {
   "ISO3": "CZE",
   "ISO2": "CZ",
   "ISONUMERIC": 203,
   "DIAL": "420",
   "NAME": "Czechia"
 },
 {
   "ISO3": "BEN",
   "ISO2": "BJ",
   "ISONUMERIC": 204,
   "DIAL": "229",
   "NAME": "Benin"
 },
 {
   "ISO3": "DNK",
   "ISO2": "DK",
   "ISONUMERIC": 208,
   "DIAL": "45",
   "NAME": "Denmark"
 },
 {
   "ISO3": "DMA",
   "ISO2": "DM",
   "ISONUMERIC": 212,
   "DIAL": "1-767",
   "NAME": "Dominica"
 },
 {
   "ISO3": "DOM",
   "ISO2": "DO",
   "ISONUMERIC": 214,
   "DIAL": "1",
   "NAME": "Dominican Republic"
 },
 {
   "ISO3": "ECU",
   "ISO2": "EC",
   "ISONUMERIC": 218,
   "DIAL": "593",
   "NAME": "Ecuador"
 },
 {
   "ISO3": "SLV",
   "ISO2": "SV",
   "ISONUMERIC": 222,
   "DIAL": "503",
   "NAME": "El Salvador"
 },
 {
   "ISO3": "GNQ",
   "ISO2": "GQ",
   "ISONUMERIC": 226,
   "DIAL": "240",
   "NAME": "Equatorial Guinea"
 },
 {
   "ISO3": "ETH",
   "ISO2": "ET",
   "ISONUMERIC": 231,
   "DIAL": "251",
   "NAME": "Ethiopia"
 },
 {
   "ISO3": "ERI",
   "ISO2": "ER",
   "ISONUMERIC": 232,
   "DIAL": "291",
   "NAME": "Eritrea"
 },
 {
   "ISO3": "EST",
   "ISO2": "EE",
   "ISONUMERIC": 233,
   "DIAL": "372",
   "NAME": "Estonia"
 },
 {
   "ISO3": "FRO",
   "ISO2": "FO",
   "ISONUMERIC": 234,
   "DIAL": "298",
   "NAME": "Faroe Islands"
 },
 {
   "ISO3": "FLK",
   "ISO2": "FK",
   "ISONUMERIC": 238,
   "DIAL": "500",
   "NAME": "Falkland Islands"
 },
 {
   "ISO3": "SGS",
   "ISO2": "GS",
   "ISONUMERIC": 239,
   "DIAL": "500",
   "NAME": "South Georgia & South Sandwich Islands"
 },
 {
   "ISO3": "FJI",
   "ISO2": "FJ",
   "ISONUMERIC": 242,
   "DIAL": "679",
   "NAME": "Fiji"
 },
 {
   "ISO3": "FIN",
   "ISO2": "FI",
   "ISONUMERIC": 246,
   "DIAL": "358",
   "NAME": "Finland"
 },
 {
   "ISO3": "ALA",
   "ISO2": "AX",
   "ISONUMERIC": 248,
   "DIAL": "358",
   "NAME": "Åland Islands"
 },
 {
   "ISO3": "FRA",
   "ISO2": "FR",
   "ISONUMERIC": 250,
   "DIAL": "33",
   "NAME": "France"
 },
 {
   "ISO3": "GUF",
   "ISO2": "GF",
   "ISONUMERIC": 254,
   "DIAL": "594",
   "NAME": "French Guiana"
 },
 {
   "ISO3": "PYF",
   "ISO2": "PF",
   "ISONUMERIC": 258,
   "DIAL": "689",
   "NAME": "French Polynesia"
 },
 {
   "ISO3": "ATF",
   "ISO2": "TF",
   "ISONUMERIC": 260,
   "DIAL": "262",
   "NAME": "French Southern Territories"
 },
 {
   "ISO3": "DJI",
   "ISO2": "DJ",
   "ISONUMERIC": 262,
   "DIAL": "253",
   "NAME": "Djibouti"
 },
 {
   "ISO3": "GAB",
   "ISO2": "GA",
   "ISONUMERIC": 266,
   "DIAL": "241",
   "NAME": "Gabon"
 },
 {
   "ISO3": "GEO",
   "ISO2": "GE",
   "ISONUMERIC": 268,
   "DIAL": "995",
   "NAME": "Georgia"
 },
 {
   "ISO3": "GMB",
   "ISO2": "GM",
   "ISONUMERIC": 270,
   "DIAL": "220",
   "NAME": "Gambia"
 },
 {
   "ISO3": "PSE",
   "ISO2": "PS",
   "ISONUMERIC": 275,
   "DIAL": "970",
   "NAME": "Palestine"
 },
 {
   "ISO3": "DEU",
   "ISO2": "DE",
   "ISONUMERIC": 276,
   "DIAL": "49",
   "NAME": "Germany"
 },
 {
   "ISO3": "GHA",
   "ISO2": "GH",
   "ISONUMERIC": 288,
   "DIAL": "233",
   "NAME": "Ghana"
 },
 {
   "ISO3": "GIB",
   "ISO2": "GI",
   "ISONUMERIC": 292,
   "DIAL": "350",
   "NAME": "Gibraltar"
 },
 {
   "ISO3": "KIR",
   "ISO2": "KI",
   "ISONUMERIC": 296,
   "DIAL": "686",
   "NAME": "Kiribati"
 },
 {
   "ISO3": "GRC",
   "ISO2": "GR",
   "ISONUMERIC": 300,
   "DIAL": "30",
   "NAME": "Greece"
 },
 {
   "ISO3": "GRL",
   "ISO2": "GL",
   "ISONUMERIC": 304,
   "DIAL": "299",
   "NAME": "Greenland"
 },
 {
   "ISO3": "GRD",
   "ISO2": "GD",
   "ISONUMERIC": 308,
   "DIAL": "1-473",
   "NAME": "Grenada"
 },
 {
   "ISO3": "GLP",
   "ISO2": "GP",
   "ISONUMERIC": 312,
   "DIAL": "590",
   "NAME": "Guadeloupe"
 },
 {
   "ISO3": "GUM",
   "ISO2": "GU",
   "ISONUMERIC": 316,
   "DIAL": "1-671",
   "NAME": "Guam"
 },
 {
   "ISO3": "GTM",
   "ISO2": "GT",
   "ISONUMERIC": 320,
   "DIAL": "502",
   "NAME": "Guatemala"
 },
 {
   "ISO3": "GIN",
   "ISO2": "GN",
   "ISONUMERIC": 324,
   "DIAL": "224",
   "NAME": "Guinea"
 },
 {
   "ISO3": "GUY",
   "ISO2": "GY",
   "ISONUMERIC": 328,
   "DIAL": "592",
   "NAME": "Guyana"
 },
 {
   "ISO3": "HTI",
   "ISO2": "HT",
   "ISONUMERIC": 332,
   "DIAL": "509",
   "NAME": "Haiti"
 },
 {
   "ISO3": "HMD",
   "ISO2": "HM",
   "ISONUMERIC": 334,
   "DIAL": "672",
   "NAME": "Heard & McDonald Islands"
 },
 {
   "ISO3": "VAT",
   "ISO2": "VA",
   "ISONUMERIC": 336,
   "DIAL": "39-06",
   "NAME": "Vatican City"
 },
 {
   "ISO3": "HND",
   "ISO2": "HN",
   "ISONUMERIC": 340,
   "DIAL": "504",
   "NAME": "Honduras"
 },
 {
   "ISO3": "HKG",
   "ISO2": "HK",
   "ISONUMERIC": 344,
   "DIAL": "852",
   "NAME": "Hong Kong"
 },
 {
   "ISO3": "HUN",
   "ISO2": "HU",
   "ISONUMERIC": 348,
   "DIAL": "36",
   "NAME": "Hungary"
 },
 {
   "ISO3": "ISL",
   "ISO2": "IS",
   "ISONUMERIC": 352,
   "DIAL": "354",
   "NAME": "Iceland"
 },
 {
   "ISO3": "IND",
   "ISO2": "IN",
   "ISONUMERIC": 356,
   "DIAL": "91",
   "NAME": "India"
 },
 {
   "ISO3": "IDN",
   "ISO2": "ID",
   "ISONUMERIC": 360,
   "DIAL": "62",
   "NAME": "Indonesia"
 },
 {
   "ISO3": "IRN",
   "ISO2": "IR",
   "ISONUMERIC": 364,
   "DIAL": "98",
   "NAME": "Iran"
 },
 {
   "ISO3": "IRQ",
   "ISO2": "IQ",
   "ISONUMERIC": 368,
   "DIAL": "964",
   "NAME": "Iraq"
 },
 {
   "ISO3": "IRL",
   "ISO2": "IE",
   "ISONUMERIC": 372,
   "DIAL": "353",
   "NAME": "Ireland"
 },
 {
   "ISO3": "ISR",
   "ISO2": "IL",
   "ISONUMERIC": 376,
   "DIAL": "972",
   "NAME": "Israel"
 },
 {
   "ISO3": "ITA",
   "ISO2": "IT",
   "ISONUMERIC": 380,
   "DIAL": "39",
   "NAME": "Italy"
 },
 {
   "ISO3": "CIV",
   "ISO2": "CI",
   "ISONUMERIC": 384,
   "DIAL": "225",
   "NAME": "Côte d’Ivoire"
 },
 {
   "ISO3": "JAM",
   "ISO2": "JM",
   "ISONUMERIC": 388,
   "DIAL": "1-876",
   "NAME": "Jamaica"
 },
 {
   "ISO3": "JPN",
   "ISO2": "JP",
   "ISONUMERIC": 392,
   "DIAL": "81",
   "NAME": "Japan"
 },
 {
   "ISO3": "KAZ",
   "ISO2": "KZ",
   "ISONUMERIC": 398,
   "DIAL": "7",
   "NAME": "Kazakhstan"
 },
 {
   "ISO3": "JOR",
   "ISO2": "JO",
   "ISONUMERIC": 400,
   "DIAL": "962",
   "NAME": "Jordan"
 },
 {
   "ISO3": "KEN",
   "ISO2": "KE",
   "ISONUMERIC": 404,
   "DIAL": "254",
   "NAME": "Kenya"
 },
 {
   "ISO3": "PRK",
   "ISO2": "KP",
   "ISONUMERIC": 408,
   "DIAL": "850",
   "NAME": "North Korea"
 },
 {
   "ISO3": "KOR",
   "ISO2": "KR",
   "ISONUMERIC": 410,
   "DIAL": "82",
   "NAME": "South Korea"
 },
 {
   "ISO3": "KWT",
   "ISO2": "KW",
   "ISONUMERIC": 414,
   "DIAL": "965",
   "NAME": "Kuwait"
 },
 {
   "ISO3": "KGZ",
   "ISO2": "KG",
   "ISONUMERIC": 417,
   "DIAL": "996",
   "NAME": "Kyrgyzstan"
 },
 {
   "ISO3": "LAO",
   "ISO2": "LA",
   "ISONUMERIC": 418,
   "DIAL": "856",
   "NAME": "Laos"
 },
 {
   "ISO3": "LBN",
   "ISO2": "LB",
   "ISONUMERIC": 422,
   "DIAL": "961",
   "NAME": "Lebanon"
 },
 {
   "ISO3": "LSO",
   "ISO2": "LS",
   "ISONUMERIC": 426,
   "DIAL": "266",
   "NAME": "Lesotho"
 },
 {
   "ISO3": "LVA",
   "ISO2": "LV",
   "ISONUMERIC": 428,
   "DIAL": "371",
   "NAME": "Latvia"
 },
 {
   "ISO3": "LBR",
   "ISO2": "LR",
   "ISONUMERIC": 430,
   "DIAL": "231",
   "NAME": "Liberia"
 },
 {
   "ISO3": "LBY",
   "ISO2": "LY",
   "ISONUMERIC": 434,
   "DIAL": "218",
   "NAME": "Libya"
 },
 {
   "ISO3": "LIE",
   "ISO2": "LI",
   "ISONUMERIC": 438,
   "DIAL": "423",
   "NAME": "Liechtenstein"
 },
 {
   "ISO3": "LTU",
   "ISO2": "LT",
   "ISONUMERIC": 440,
   "DIAL": "370",
   "NAME": "Lithuania"
 },
 {
   "ISO3": "LUX",
   "ISO2": "LU",
   "ISONUMERIC": 442,
   "DIAL": "352",
   "NAME": "Luxembourg"
 },
 {
   "ISO3": "MAC",
   "ISO2": "MO",
   "ISONUMERIC": 446,
   "DIAL": "853",
   "NAME": "Macau"
 },
 {
   "ISO3": "MDG",
   "ISO2": "MG",
   "ISONUMERIC": 450,
   "DIAL": "261",
   "NAME": "Madagascar"
 },
 {
   "ISO3": "MWI",
   "ISO2": "MW",
   "ISONUMERIC": 454,
   "DIAL": "265",
   "NAME": "Malawi"
 },
 {
   "ISO3": "MYS",
   "ISO2": "MY",
   "ISONUMERIC": 458,
   "DIAL": "60",
   "NAME": "Malaysia"
 },
 {
   "ISO3": "MDV",
   "ISO2": "MV",
   "ISONUMERIC": 462,
   "DIAL": "960",
   "NAME": "Maldives"
 },
 {
   "ISO3": "MLI",
   "ISO2": "ML",
   "ISONUMERIC": 466,
   "DIAL": "223",
   "NAME": "Mali"
 },
 {
   "ISO3": "MLT",
   "ISO2": "MT",
   "ISONUMERIC": 470,
   "DIAL": "356",
   "NAME": "Malta"
 },
 {
   "ISO3": "MTQ",
   "ISO2": "MQ",
   "ISONUMERIC": 474,
   "DIAL": "596",
   "NAME": "Martinique"
 },
 {
   "ISO3": "MRT",
   "ISO2": "MR",
   "ISONUMERIC": 478,
   "DIAL": "222",
   "NAME": "Mauritania"
 },
 {
   "ISO3": "MUS",
   "ISO2": "MU",
   "ISONUMERIC": 480,
   "DIAL": "230",
   "NAME": "Mauritius"
 },
 {
   "ISO3": "MEX",
   "ISO2": "MX",
   "ISONUMERIC": 484,
   "DIAL": "52",
   "NAME": "Mexico"
 },
 {
   "ISO3": "MCO",
   "ISO2": "MC",
   "ISONUMERIC": 492,
   "DIAL": "377",
   "NAME": "Monaco"
 },
 {
   "ISO3": "MNG",
   "ISO2": "MN",
   "ISONUMERIC": 496,
   "DIAL": "976",
   "NAME": "Mongolia"
 },
 {
   "ISO3": "MDA",
   "ISO2": "MD",
   "ISONUMERIC": 498,
   "DIAL": "373",
   "NAME": "Moldova"
 },
 {
   "ISO3": "MNE",
   "ISO2": "ME",
   "ISONUMERIC": 499,
   "DIAL": "382",
   "NAME": "Montenegro"
 },
 {
   "ISO3": "MSR",
   "ISO2": "MS",
   "ISONUMERIC": 500,
   "DIAL": "1-664",
   "NAME": "Montserrat"
 },
 {
   "ISO3": "MAR",
   "ISO2": "MA",
   "ISONUMERIC": 504,
   "DIAL": "212",
   "NAME": "Morocco"
 },
 {
   "ISO3": "MOZ",
   "ISO2": "MZ",
   "ISONUMERIC": 508,
   "DIAL": "258",
   "NAME": "Mozambique"
 },
 {
   "ISO3": "OMN",
   "ISO2": "OM",
   "ISONUMERIC": 512,
   "DIAL": "968",
   "NAME": "Oman"
 },
 {
   "ISO3": "NAM",
   "ISO2": "NA",
   "ISONUMERIC": 516,
   "DIAL": "264",
   "NAME": "Namibia"
 },
 {
   "ISO3": "NRU",
   "ISO2": "NR",
   "ISONUMERIC": 520,
   "DIAL": "674",
   "NAME": "Nauru"
 },
 {
   "ISO3": "NPL",
   "ISO2": "NP",
   "ISONUMERIC": 524,
   "DIAL": "977",
   "NAME": "Nepal"
 },
 {
   "ISO3": "NLD",
   "ISO2": "NL",
   "ISONUMERIC": 528,
   "DIAL": "31",
   "NAME": "Netherlands"
 },
 {
   "ISO3": "CUW",
   "ISO2": "CW",
   "ISONUMERIC": 531,
   "DIAL": "599",
   "NAME": "Curaçao"
 },
 {
   "ISO3": "ABW",
   "ISO2": "AW",
   "ISONUMERIC": 533,
   "DIAL": "297",
   "NAME": "Aruba"
 },
 {
   "ISO3": "SXM",
   "ISO2": "SX",
   "ISONUMERIC": 534,
   "DIAL": "1-721",
   "NAME": "Sint Maarten"
 },
 {
   "ISO3": "BES",
   "ISO2": "BQ",
   "ISONUMERIC": 535,
   "DIAL": "599",
   "NAME": "Caribbean Netherlands"
 },
 {
   "ISO3": "NCL",
   "ISO2": "NC",
   "ISONUMERIC": 540,
   "DIAL": "687",
   "NAME": "New Caledonia"
 },
 {
   "ISO3": "VUT",
   "ISO2": "VU",
   "ISONUMERIC": 548,
   "DIAL": "678",
   "NAME": "Vanuatu"
 },
 {
   "ISO3": "NZL",
   "ISO2": "NZ",
   "ISONUMERIC": 554,
   "DIAL": "64",
   "NAME": "New Zealand"
 },
 {
   "ISO3": "NIC",
   "ISO2": "NI",
   "ISONUMERIC": 558,
   "DIAL": "505",
   "NAME": "Nicaragua"
 },
 {
   "ISO3": "NER",
   "ISO2": "NE",
   "ISONUMERIC": 562,
   "DIAL": "227",
   "NAME": "Niger"
 },
 {
   "ISO3": "NGA",
   "ISO2": "NG",
   "ISONUMERIC": 566,
   "DIAL": "234",
   "NAME": "Nigeria"
 },
 {
   "ISO3": "NIU",
   "ISO2": "NU",
   "ISONUMERIC": 570,
   "DIAL": "683",
   "NAME": "Niue"
 },
 {
   "ISO3": "NFK",
   "ISO2": "NF",
   "ISONUMERIC": 574,
   "DIAL": "672",
   "NAME": "Norfolk Island"
 },
 {
   "ISO3": "NOR",
   "ISO2": "NO",
   "ISONUMERIC": 578,
   "DIAL": "47",
   "NAME": "Norway"
 },
 {
   "ISO3": "MNP",
   "ISO2": "MP",
   "ISONUMERIC": 580,
   "DIAL": "1-670",
   "NAME": "Northern Mariana Islands"
 },
 {
   "ISO3": "FSM",
   "ISO2": "FM",
   "ISONUMERIC": 583,
   "DIAL": "691",
   "NAME": "Micronesia"
 },
 {
   "ISO3": "MHL",
   "ISO2": "MH",
   "ISONUMERIC": 584,
   "DIAL": "692",
   "NAME": "Marshall Islands"
 },
 {
   "ISO3": "PLW",
   "ISO2": "PW",
   "ISONUMERIC": 585,
   "DIAL": "680",
   "NAME": "Palau"
 },
 {
   "ISO3": "PAK",
   "ISO2": "PK",
   "ISONUMERIC": 586,
   "DIAL": "92",
   "NAME": "Pakistan"
 },
 {
   "ISO3": "PAN",
   "ISO2": "PA",
   "ISONUMERIC": 591,
   "DIAL": "507",
   "NAME": "Panama"
 },
 {
   "ISO3": "PNG",
   "ISO2": "PG",
   "ISONUMERIC": 598,
   "DIAL": "675",
   "NAME": "Papua New Guinea"
 },
 {
   "ISO3": "PRY",
   "ISO2": "PY",
   "ISONUMERIC": 600,
   "DIAL": "595",
   "NAME": "Paraguay"
 },
 {
   "ISO3": "PER",
   "ISO2": "PE",
   "ISONUMERIC": 604,
   "DIAL": "51",
   "NAME": "Peru"
 },
 {
   "ISO3": "PHL",
   "ISO2": "PH",
   "ISONUMERIC": 608,
   "DIAL": "63",
   "NAME": "Philippines"
 },
 {
   "ISO3": "PCN",
   "ISO2": "PN",
   "ISONUMERIC": 612,
   "DIAL": "870",
   "NAME": "Pitcairn Islands"
 },
 {
   "ISO3": "POL",
   "ISO2": "PL",
   "ISONUMERIC": 616,
   "DIAL": "48",
   "NAME": "Poland"
 },
 {
   "ISO3": "PRT",
   "ISO2": "PT",
   "ISONUMERIC": 620,
   "DIAL": "351",
   "NAME": "Portugal"
 },
 {
   "ISO3": "GNB",
   "ISO2": "GW",
   "ISONUMERIC": 624,
   "DIAL": "245",
   "NAME": "Guinea-Bissau"
 },
 {
   "ISO3": "TLS",
   "ISO2": "TL",
   "ISONUMERIC": 626,
   "DIAL": "670",
   "NAME": "Timor-Leste"
 },
 {
   "ISO3": "PRI",
   "ISO2": "PR",
   "ISONUMERIC": 630,
   "DIAL": "1",
   "NAME": "Puerto Rico"
 },
 {
   "ISO3": "QAT",
   "ISO2": "QA",
   "ISONUMERIC": 634,
   "DIAL": "974",
   "NAME": "Qatar"
 },
 {
   "ISO3": "REU",
   "ISO2": "RE",
   "ISONUMERIC": 638,
   "DIAL": "262",
   "NAME": "Réunion"
 },
 {
   "ISO3": "ROU",
   "ISO2": "RO",
   "ISONUMERIC": 642,
   "DIAL": "40",
   "NAME": "Romania"
 },
 {
   "ISO3": "RUS",
   "ISO2": "RU",
   "ISONUMERIC": 643,
   "DIAL": "7",
   "NAME": "Russia"
 },
 {
   "ISO3": "RWA",
   "ISO2": "RW",
   "ISONUMERIC": 646,
   "DIAL": "250",
   "NAME": "Rwanda"
 },
 {
   "ISO3": "BLM",
   "ISO2": "BL",
   "ISONUMERIC": 652,
   "DIAL": "590",
   "NAME": "St. Barthélemy"
 },
 {
   "ISO3": "SHN",
   "ISO2": "SH",
   "ISONUMERIC": 654,
   "DIAL": "290",
   "NAME": "St. Helena"
 },
 {
   "ISO3": "KNA",
   "ISO2": "KN",
   "ISONUMERIC": 659,
   "DIAL": "1-869",
   "NAME": "St. Kitts & Nevis"
 },
 {
   "ISO3": "AIA",
   "ISO2": "AI",
   "ISONUMERIC": 660,
   "DIAL": "1-264",
   "NAME": "Anguilla"
 },
 {
   "ISO3": "LCA",
   "ISO2": "LC",
   "ISONUMERIC": 662,
   "DIAL": "1-758",
   "NAME": "St. Lucia"
 },
 {
   "ISO3": "MAF",
   "ISO2": "MF",
   "ISONUMERIC": 663,
   "DIAL": "590",
   "NAME": "St. Martin"
 },
 {
   "ISO3": "SPM",
   "ISO2": "PM",
   "ISONUMERIC": 666,
   "DIAL": "508",
   "NAME": "St. Pierre & Miquelon"
 },
 {
   "ISO3": "VCT",
   "ISO2": "VC",
   "ISONUMERIC": 670,
   "DIAL": "1-784",
   "NAME": "St. Vincent & Grenadines"
 },
 {
   "ISO3": "SMR",
   "ISO2": "SM",
   "ISONUMERIC": 674,
   "DIAL": "378",
   "NAME": "San Marino"
 },
 {
   "ISO3": "STP",
   "ISO2": "ST",
   "ISONUMERIC": 678,
   "DIAL": "239",
   "NAME": "São Tomé & Príncipe"
 },
 {
   "ISO3": "SAU",
   "ISO2": "SA",
   "ISONUMERIC": 682,
   "DIAL": "966",
   "NAME": "Saudi Arabia"
 },
 {
   "ISO3": "SEN",
   "ISO2": "SN",
   "ISONUMERIC": 686,
   "DIAL": "221",
   "NAME": "Senegal"
 },
 {
   "ISO3": "SRB",
   "ISO2": "RS",
   "ISONUMERIC": 688,
   "DIAL": "381",
   "NAME": "Serbia"
 },
 {
   "ISO3": "SYC",
   "ISO2": "SC",
   "ISONUMERIC": 690,
   "DIAL": "248",
   "NAME": "Seychelles"
 },
 {
   "ISO3": "SLE",
   "ISO2": "SL",
   "ISONUMERIC": 694,
   "DIAL": "232",
   "NAME": "Sierra Leone"
 },
 {
   "ISO3": "SGP",
   "ISO2": "SG",
   "ISONUMERIC": 702,
   "DIAL": "65",
   "NAME": "Singapore"
 },
 {
   "ISO3": "SVK",
   "ISO2": "SK",
   "ISONUMERIC": 703,
   "DIAL": "421",
   "NAME": "Slovakia"
 },
 {
   "ISO3": "VNM",
   "ISO2": "VN",
   "ISONUMERIC": 704,
   "DIAL": "84",
   "NAME": "Vietnam"
 },
 {
   "ISO3": "SVN",
   "ISO2": "SI",
   "ISONUMERIC": 705,
   "DIAL": "386",
   "NAME": "Slovenia"
 },
 {
   "ISO3": "SOM",
   "ISO2": "SO",
   "ISONUMERIC": 706,
   "DIAL": "252",
   "NAME": "Somalia"
 },
 {
   "ISO3": "ZAF",
   "ISO2": "ZA",
   "ISONUMERIC": 710,
   "DIAL": "27",
   "NAME": "South Africa"
 },
 {
   "ISO3": "ZWE",
   "ISO2": "ZW",
   "ISONUMERIC": 716,
   "DIAL": "263",
   "NAME": "Zimbabwe"
 },
 {
   "ISO3": "ESP",
   "ISO2": "ES",
   "ISONUMERIC": 724,
   "DIAL": "34",
   "NAME": "Spain"
 },
 {
   "ISO3": "SSD",
   "ISO2": "SS",
   "ISONUMERIC": 728,
   "DIAL": "211",
   "NAME": "South Sudan"
 },
 {
   "ISO3": "SDN",
   "ISO2": "SD",
   "ISONUMERIC": 729,
   "DIAL": "249",
   "NAME": "Sudan"
 },
 {
   "ISO3": "ESH",
   "ISO2": "EH",
   "ISONUMERIC": 732,
   "DIAL": "212",
   "NAME": "Western Sahara"
 },
 {
   "ISO3": "SUR",
   "ISO2": "SR",
   "ISONUMERIC": 740,
   "DIAL": "597",
   "NAME": "Suriname"
 },
 {
   "ISO3": "SJM",
   "ISO2": "SJ",
   "ISONUMERIC": 744,
   "DIAL": "47",
   "NAME": "Svalbard & Jan Mayen"
 },
 {
   "ISO3": "SWZ",
   "ISO2": "SZ",
   "ISONUMERIC": 748,
   "DIAL": "268",
   "NAME": "Eswatini"
 },
 {
   "ISO3": "SWE",
   "ISO2": "SE",
   "ISONUMERIC": 752,
   "DIAL": "46",
   "NAME": "Sweden"
 },
 {
   "ISO3": "CHE",
   "ISO2": "CH",
   "ISONUMERIC": 756,
   "DIAL": "41",
   "NAME": "Switzerland"
 },
 {
   "ISO3": "SYR",
   "ISO2": "SY",
   "ISONUMERIC": 760,
   "DIAL": "963",
   "NAME": "Syria"
 },
 {
   "ISO3": "TJK",
   "ISO2": "TJ",
   "ISONUMERIC": 762,
   "DIAL": "992",
   "NAME": "Tajikistan"
 },
 {
   "ISO3": "THA",
   "ISO2": "TH",
   "ISONUMERIC": 764,
   "DIAL": "66",
   "NAME": "Thailand"
 },
 {
   "ISO3": "TGO",
   "ISO2": "TG",
   "ISONUMERIC": 768,
   "DIAL": "228",
   "NAME": "Togo"
 },
 {
   "ISO3": "TKL",
   "ISO2": "TK",
   "ISONUMERIC": 772,
   "DIAL": "690",
   "NAME": "Tokelau"
 },
 {
   "ISO3": "TON",
   "ISO2": "TO",
   "ISONUMERIC": 776,
   "DIAL": "676",
   "NAME": "Tonga"
 },
 {
   "ISO3": "TTO",
   "ISO2": "TT",
   "ISONUMERIC": 780,
   "DIAL": "1-868",
   "NAME": "Trinidad & Tobago"
 },
 {
   "ISO3": "ARE",
   "ISO2": "AE",
   "ISONUMERIC": 784,
   "DIAL": "971",
   "NAME": "United Arab Emirates"
 },
 {
   "ISO3": "TUN",
   "ISO2": "TN",
   "ISONUMERIC": 788,
   "DIAL": "216",
   "NAME": "Tunisia"
 },
 {
   "ISO3": "TUR",
   "ISO2": "TR",
   "ISONUMERIC": 792,
   "DIAL": "90",
   "NAME": "Turkey"
 },
 {
   "ISO3": "TKM",
   "ISO2": "TM",
   "ISONUMERIC": 795,
   "DIAL": "993",
   "NAME": "Turkmenistan"
 },
 {
   "ISO3": "TCA",
   "ISO2": "TC",
   "ISONUMERIC": 796,
   "DIAL": "1-649",
   "NAME": "Turks & Caicos Islands"
 },
 {
   "ISO3": "TUV",
   "ISO2": "TV",
   "ISONUMERIC": 798,
   "DIAL": "688",
   "NAME": "Tuvalu"
 },
 {
   "ISO3": "UGA",
   "ISO2": "UG",
   "ISONUMERIC": 800,
   "DIAL": "256",
   "NAME": "Uganda"
 },
 {
   "ISO3": "UKR",
   "ISO2": "UA",
   "ISONUMERIC": 804,
   "DIAL": "380",
   "NAME": "Ukraine"
 },
 {
   "ISO3": "MKD",
   "ISO2": "MK",
   "ISONUMERIC": 807,
   "DIAL": "389",
   "NAME": "North Macedonia"
 },
 {
   "ISO3": "EGY",
   "ISO2": "EG",
   "ISONUMERIC": 818,
   "DIAL": "20",
   "NAME": "Egypt"
 },
 {
   "ISO3": "GBR",
   "ISO2": "GB",
   "ISONUMERIC": 826,
   "DIAL": "44",
   "NAME": "UK"
 },
 {
   "ISO3": "GGY",
   "ISO2": "GG",
   "ISONUMERIC": 831,
   "DIAL": "44",
   "NAME": "Guernsey"
 },
 {
   "ISO3": "JEY",
   "ISO2": "JE",
   "ISONUMERIC": 832,
   "DIAL": "44",
   "NAME": "Jersey"
 },
 {
   "ISO3": "IMN",
   "ISO2": "IM",
   "ISONUMERIC": 833,
   "DIAL": "44",
   "NAME": "Isle of Man"
 },
 {
   "ISO3": "TZA",
   "ISO2": "TZ",
   "ISONUMERIC": 834,
   "DIAL": "255",
   "NAME": "Tanzania"
 },
 {
   "ISO3": "USA",
   "ISO2": "US",
   "ISONUMERIC": 840,
   "DIAL": "1",
   "NAME": "United States"
 },
 {
   "ISO3": "VIR",
   "ISO2": "VI",
   "ISONUMERIC": 850,
   "DIAL": "1",
   "NAME": "U.S. Virgin Islands"
 },
 {
   "ISO3": "BFA",
   "ISO2": "BF",
   "ISONUMERIC": 854,
   "DIAL": "226",
   "NAME": "Burkina Faso"
 },
 {
   "ISO3": "URY",
   "ISO2": "UY",
   "ISONUMERIC": 858,
   "DIAL": "598",
   "NAME": "Uruguay"
 },
 {
   "ISO3": "UZB",
   "ISO2": "UZ",
   "ISONUMERIC": 860,
   "DIAL": "998",
   "NAME": "Uzbekistan"
 },
 {
   "ISO3": "VEN",
   "ISO2": "VE",
   "ISONUMERIC": 862,
   "DIAL": "58",
   "NAME": "Venezuela"
 },
 {
   "ISO3": "WLF",
   "ISO2": "WF",
   "ISONUMERIC": 876,
   "DIAL": "681",
   "NAME": "Wallis & Futuna"
 },
 {
   "ISO3": "WSM",
   "ISO2": "WS",
   "ISONUMERIC": 882,
   "DIAL": "685",
   "NAME": "Samoa"
 },
 {
   "ISO3": "YEM",
   "ISO2": "YE",
   "ISONUMERIC": 887,
   "DIAL": "967",
   "NAME": "Yemen"
 },
 {
   "ISO3": "ZMB",
   "ISO2": "ZM",
   "ISONUMERIC": 894,
   "DIAL": "260",
   "NAME": "Zambia"
 }
]