import React from 'react';
import {
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useApi, useContext } from '../context'
import { useTranslation } from 'react-i18next';
import utils from '../services/utils.service';
import { ErrorDialog } from '../components/errorDialog';
import { Redirect, useHistory } from 'react-router-dom';
import { Loading } from '../components/loading';
import { OrderWizard, OrderWizardStep } from './orderWizard';
import { Autocomplete } from '@material-ui/lab';
import { Currency } from '../services/types/currency.type';
import { DeliveryMethod } from '../services/types/deliveryMethod.type';
import { Settings } from '../services/types/settings.type';


type Form = {
  cardNumber: number | null | undefined;
  currency: Currency | null;
  deliveryMethod: DeliveryMethod | null;
}
type FormErrors = {
  cardNumber: string | undefined;
  currency: string | undefined;
  deliveryMethod: string | undefined;
}

type FormWithErrors = {
  form: Form;
  errors: FormErrors;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    radioLabel: {
      width: '100%',
      marginBottom: theme.spacing(1)
    }
  }),
);

export const NewCardShipping = () => {
  const { t, i18n } = useTranslation('newCard');
  const api = useApi();
  const classes = useStyles();
  const history = useHistory();
  const context = useContext();

  const [settings, setSettings] = React.useState<Settings | undefined>();
  const [currencies, setCurrencies] = React.useState<Currency[]>([]);
  const [deliveryMethods, setDeliveryMethods] = React.useState<DeliveryMethod[]>([]);

  const [loadingCurrencies, setLoadingCurrencies] = React.useState(true);
  const [loadingDeliveryMethods, setLoadingDeliveryMethods] = React.useState(true);
  const [loadingCardPrice, setLoadingCardPrice] = React.useState(true);

  const [error, setError] = React.useState(false);

  const emptyFormErrors = {
    currency: undefined,
    cardNumber: undefined,
    deliveryMethod: undefined,
  };

  const emptyForm = {
    cardNumber: context.data.paymentCurrency ? context.data.cardNumber : 1,
    currency: context.data.paymentCurrency ? context.data.paymentCurrency : null,
    deliveryMethod: context.data.deliveryMethod ? context.data.deliveryMethod : null,
  }

  const [formWithErrors, setFormWithErrors] = React.useReducer((state: FormWithErrors, action: { form?: Partial<Form>, errors?: Partial<FormErrors> }) => {
    const newFormWithErrors: FormWithErrors = { ...state };
    if (action.form) {
      newFormWithErrors.form = {
        ...state.form,
        ...action.form
      }
    }
    if (action.errors) {
      newFormWithErrors.errors = {
        ...state.errors,
        ...action.errors
      }
    }
    return newFormWithErrors;
  }, { form: emptyForm, errors: emptyFormErrors });


  const form = formWithErrors.form;
  const formErrors = formWithErrors.errors;

  const validate = () => {
    const errors = Object.assign({}, emptyFormErrors);
    if (form.cardNumber === null || form.cardNumber === undefined) {
      errors.cardNumber = t('REQUIRED');
    }
    if (form.currency === null) {
      errors.currency = t('REQUIRED');
    }
    if (form.deliveryMethod === null) {
      errors.deliveryMethod = t('REQUIRED');
    }
    const isValid = Object.keys(errors).find(k => (errors as any)[k] !== undefined) === undefined;
    if (!isValid) {
      setFormWithErrors({ errors });
    }
    return isValid;
  }

  const loadCurrencies = () => {
    utils.runAsync(async () => {
      setLoadingCurrencies(true);
      const avaialbleCurrencies = await api.getPaymentCurrencies();
      const euroCurrency = avaialbleCurrencies.find(c => c.iso === 'EUR');
      setCurrencies(avaialbleCurrencies);
      if (context.data.paymentCurrency === undefined && euroCurrency) {
        setFormWithErrors({ form: { currency: euroCurrency } })
      }
    }, (e) => {
      setLoadingCurrencies(false);
      if (e) {
        setError(true);
      }
    });
  }

  const loadDeliveryMethods = () => {
    utils.runAsync(async () => {
      setLoadingDeliveryMethods(true);
      const filteredDeliveryMethods = ((await api.getDeliveryMethods()).filter(d => (
        d.available_countries.includes(context.data.deliveryAddress!.country.iso2) ||
        d.available_countries.includes(context.data.deliveryAddress!.country.iso3)
      ) && d.enabled));
      setDeliveryMethods(filteredDeliveryMethods);
      if (context.data.deliveryMethod === undefined && filteredDeliveryMethods.length > 0) {
        setFormWithErrors({ form: { deliveryMethod: filteredDeliveryMethods[0] } })
      }
    }, (e) => {
      setLoadingDeliveryMethods(false);
      if (e) {
        setError(true);
      }
    });
  }

  const loadCardPrice = () => {
    utils.runAsync(async () => {
      setLoadingCardPrice(true);
      setSettings((await api.getSettings()));
    }, (e) => {
      setLoadingCardPrice(false);
      if (e) {
        setError(true);
      }
    });
  }


  React.useEffect(() => {
    loadCurrencies();
    loadDeliveryMethods();
    loadCardPrice();
  }, []);


  const getRequestableCards = () => {
    const cards = [];
    for (let i = 1; i <= settings!.max_requested_cards; i++) {
      cards.push(i);
    }
    return cards;
  }


  const handleDeliveryMethodChange = (e: any) => {
    const deliveryMethodId = parseInt(e.target.value);
    const dm = deliveryMethods.find(d => d.id === deliveryMethodId);
    setFormWithErrors({ form: { deliveryMethod: dm } });
  }

  const next = () => {
    if (validate()) {
      context.setData({
        cardNumber: form.cardNumber!,
        deliveryMethod: form.deliveryMethod!,
        paymentCurrency: form.currency!,
        cardPrice: settings!.card_price * form.cardNumber!
      });
      history.push('/new-card/checkout')
    }
  }

  if (!context.data.deliveryAddress) {
    return <Redirect to='/new-card/destination' />
  }

  if (loadingCurrencies || loadingCardPrice || loadingDeliveryMethods) {
    return <Loading />
  }

  const getTotal = () => {
    if (settings) {
      if (form.deliveryMethod) {
        return settings.card_price * form.cardNumber! + form.deliveryMethod.price;
      }
      return settings.card_price * form.cardNumber!;
    }
    return 0;
  }

  return (
    <>
      <ErrorDialog
        open={error}
        title={t('LOADING_ERROR_TITLE')}
        message={t('LOADING_ERROR_MESSAGE')}
        onClose={() => {
          setError(false);
          loadCurrencies();
          loadCardPrice();
          loadDeliveryMethods();
        }}
      />
      <OrderWizard
        canGoNext={form.deliveryMethod !== null}
        back={() => history.push('/new-card/destination')}
        loading={false}
        next={next}
        step={OrderWizardStep.DELIVERY_METHOD}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('PAYMENT_CURRENCY_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              onChange={(event, value) => {
                setFormWithErrors({ form: { cardNumber: value }, errors: { cardNumber: undefined } });
              }}
              getOptionSelected={(option, value) => option === value}
              value={form.cardNumber ? form.cardNumber : 1}
              options={getRequestableCards()}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              disableClearable
              getOptionLabel={(option) => option.toString(10)}
              renderOption={(option) => option.toString(10)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('CARD_AMOUNT')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                  error={formErrors.cardNumber !== undefined}
                  helperText={formErrors.cardNumber}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              onChange={(event, value) => {
                setFormWithErrors({ form: { currency: value }, errors: { currency: undefined } });
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              value={form.currency!}
              options={currencies}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              disableClearable
              getOptionLabel={(option) => option.iso}
              renderOption={(option) => option.iso}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('PAYMENT_CURRENCY')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                  error={formErrors.currency !== undefined}
                  helperText={formErrors.currency}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('SHIPPING_METHOD_TITLE')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {deliveryMethods.length === 0 &&
              <Typography>
                {t('NO_DELIVERY_METHODS_AVAILABLE')}
              </Typography>
            }
            <FormControl component='fieldset' fullWidth>
              <RadioGroup value={form.deliveryMethod?.id} onChange={handleDeliveryMethodChange}>
                {deliveryMethods.map(d =>
                  <FormControlLabel classes={{ label: classes.radioLabel }} key={d.id} value={d.id} control={<Radio color='primary' />} label={
                    <Grid container alignItems='center' spacing={2}>
                      <Grid item xs={10}>
                        <Typography variant='subtitle2'>
                          {utils.getLocalizedText(i18n, d.name)}
                        </Typography>
                        <Typography variant='body2'>
                          {utils.getLocalizedText(i18n, d.description)}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant='subtitle2' align='right'>
                          {`${utils.currencyFormat(d.price / form.currency!.rate)} ${form.currency?.iso}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  } />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('SUMMARY')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='subtitle2'>
              {t('CARD_ISSUE_FEE')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle1' align='right'>
              {`${utils.currencyFormat(settings!.card_price * form.cardNumber!! / form.currency!.rate)} ${form.currency!.iso}`}
            </Typography>
          </Grid>
          {form.deliveryMethod &&
            <>
              <Grid item xs={8}>
                <Typography variant='subtitle2'>
                  {t('SHIPPING_FEE')}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle1' align='right'>
                  {`${utils.currencyFormat(form.deliveryMethod!.price / form.currency!.rate)} ${form.currency!.iso}`}
                </Typography>
              </Grid>
            </>
          }
          <Grid item xs={8}>
            <Typography variant='h5'>
              {t('TOTAL')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6' align='right'>
              {`${utils.currencyFormat(getTotal() / form.currency!.rate)} ${form.currency!.iso}`}
            </Typography>
          </Grid>
        </Grid>
      </OrderWizard>
    </>
  )
}