import React from 'react';
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useApi, useContext } from '../context'
import { Trans, useTranslation } from 'react-i18next';
import utils from '../services/utils.service';
import { ErrorDialog } from '../components/errorDialog';
import { CardRequestStatus } from '../services/types/cardRequest.type';
import { useHistory } from 'react-router-dom';
import { Loading } from '../components/loading';
import { OrderWizard, OrderWizardStep } from './orderWizard';
import { Country } from '../services/types/country.type';
import { Autocomplete } from '@material-ui/lab';
import { UserData } from '../services/types/userData.type';
import { Config } from '../config';
import { SettingsBrightnessTwoTone } from '@material-ui/icons';

const phoneRegexp = new RegExp('^\\+[0-9]{8,15}$');

export enum AddressType {
  MYSELF = 'MYSELF', SOMEONE_ELSE = 'DIFFERENT'
}

type Form = {
  name: string;
  lastName: string;
  address: string;
  city: string;
  region: string;
  postalCode: string;
  countryOfResidence: Country | null;
  phone: string;
}
type FormErrors = {
  name: string | undefined;
  lastName: string | undefined;
  address: string | undefined;
  city: string | undefined;
  region: string | undefined;
  postalCode: string | undefined;
  countryOfResidence: string | undefined;
  phone: string | undefined;
}

type FormWithErrors = {
  form: Form;
  errors: FormErrors;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  }),
);

const config = new Config();

export const NewCardDestination = () => {
  const { t, i18n } = useTranslation('newCard');
  const api = useApi();
  const classes = useStyles();
  const history = useHistory();
  const context = useContext();

  const [initialized, setInitialized] = React.useState(false);

  const [countries, setCountries] = React.useState<Country[]>([]);
  const [userData, setUserData] = React.useState<UserData | undefined>(undefined);

  const [loadingCardRequests, setLoadingCardRequests] = React.useState(true);
  const [loadingCountries, setLoadingCountries] = React.useState(true);
  const [loadingUserData, setLoadingUserData] = React.useState(true);

  const [error, setError] = React.useState(false);
  const [cardError, setCardError] = React.useState(false);
  const [addressType, setAddressType] = React.useState(context.data.addressType ? context.data.addressType : AddressType.MYSELF);

  const [termsAccepted, setTermsAccepted] = React.useState(false);

  const [showMustAcceptTerms, setShowMustAcceptTerms] = React.useState(false);

  const emptyFormErrors = {
    name: undefined,
    lastName: undefined,
    address: undefined,
    city: undefined,
    region: undefined,
    postalCode: undefined,
    countryOfResidence: undefined,
    phone: undefined,
  };

  const emptyForm = {
    name: utils.getOrDefault(context.data.deliveryAddress?.first_name),
    lastName: utils.getOrDefault(context.data.deliveryAddress?.last_name),
    address: utils.getOrDefault(context.data.deliveryAddress?.address),
    city: utils.getOrDefault(context.data.deliveryAddress?.city),
    region: utils.getOrDefault(context.data.deliveryAddress?.region),
    postalCode: utils.getOrDefault(context.data.deliveryAddress?.postal_code),
    countryOfResidence: context.data.deliveryAddress?.country ? context.data.deliveryAddress.country : null,
    phone: utils.getOrDefault(context.data.deliveryAddress?.phone),
  }

  const [formWithErrors, setFormWithErrors] = React.useReducer((state: FormWithErrors, action: { form?: Partial<Form>, errors?: Partial<FormErrors> }) => {
    const newFormWithErrors: FormWithErrors = { ...state };
    if (action.form) {
      newFormWithErrors.form = {
        ...state.form,
        ...action.form
      }
    }
    if (action.errors) {
      newFormWithErrors.errors = {
        ...state.errors,
        ...action.errors
      }
    }
    return newFormWithErrors;
  }, { form: emptyForm, errors: emptyFormErrors });


  const form = formWithErrors.form;
  const formErrors = formWithErrors.errors;

  const validate = () => {
    const errors = Object.assign({}, emptyFormErrors);
    if (form.name.length === 0) {
      errors.name = t('REQUIRED');
    }
    if (form.lastName.length === 0) {
      errors.lastName = t('REQUIRED');
    }
    if (form.countryOfResidence === null) {
      errors.countryOfResidence = t('REQUIRED');
    }
    if (form.region.length === 0) {
      errors.region = t('REQUIRED');
    }
    if (form.address.length === 0) {
      errors.address = t('REQUIRED');
    }
    if (form.address.length >= 48) {
      errors.address = t('MAX_LENGTH');
    }
    if (form.postalCode.length === 0) {
      errors.postalCode = t('REQUIRED');
    }
    if (form.city.length === 0) {
      errors.city = t('REQUIRED');
    }
    if (form.phone.length === 0) {
      errors.phone = t('REQUIRED');
    } else if (!phoneRegexp.test(form.phone)) {
      errors.phone = t('INVALID_PHONE');
    }
    const isValid = Object.keys(errors).find(k => (errors as any)[k] !== undefined) === undefined;
    if (!isValid) {
      setFormWithErrors({ errors });
    }
    return isValid;
  }

  const loadCountries = () => {
    utils.runAsync(async () => {
      setLoadingCountries(true);
      setCountries(await api.getCountries());
    }, (e) => {
      setLoadingCountries(false);
      if (e) {
        setError(true);
      }
    });
  }

  const loadUserData = () => {
    utils.runAsync(async () => {
      setLoadingUserData(true);
      setUserData(await api.getUserData());
    }, (e) => {
      setLoadingUserData(false);
      if (e) {
        setError(true);
      }
    });
  }

  const loadCardRequests = () => {
    utils.runAsync(async () => {
      setLoadingCardRequests(true);
      const cardRequests = await api.getCardRequests();
      const validCardRequest = cardRequests.find(
        c => (
          c.status !== CardRequestStatus.CANCELLED &&
          c.status !== CardRequestStatus.PENDING
        )
      );
      if (validCardRequest && !config.ALLOW_MULTIPLE_CARDS) {
        setCardError(true);
      }
    }, (e) => {
      setLoadingCardRequests(false);
      if (e) {
        setError(true);
      }
    });
  }

  React.useEffect(() => {
    loadCardRequests();
    loadCountries();
    loadUserData();
  }, []);

  React.useEffect(() => {
    if (initialized) {
      if (addressType === AddressType.MYSELF && userData) {
        const residenceCountry = countries.find(c => c.id === userData.profile.data.residence_country_id);
        setFormWithErrors({
          form: {
            countryOfResidence: residenceCountry,
            name: userData.profile.data.first_name,
            lastName: userData.profile.data.last_name,
            region: userData.profile.data.region,
            address: userData.profile.data.address,
            postalCode: userData.profile.data.postal_code,
            city: userData.profile.data.city,
            phone: userData.profile.data.phone
          },
          errors: emptyFormErrors
        });
      } else if (addressType === AddressType.SOMEONE_ELSE) {
        setFormWithErrors({
          form: {
            countryOfResidence: null,
            name: '',
            lastName: '',
            region: '',
            address: '',
            postalCode: '',
            city: '',
            phone: ''
          }
        });
      }
    }
    setInitialized(true);
  }, [addressType]);

  React.useEffect(() => {
    if (!context.data.addressType && userData) {
      const residenceCountry = countries.find(c => c.id === userData.profile.data.residence_country_id);
      setFormWithErrors({
        form: {
          countryOfResidence: residenceCountry,
          name: userData.profile.data.first_name,
          lastName: userData.profile.data.last_name,
          region: userData.profile.data.region,
          address: userData.profile.data.address,
          postalCode: userData.profile.data.postal_code,
          city: userData.profile.data.city,
          phone: userData.profile.data.phone
        },
        errors: emptyFormErrors
      });
    }
  }, [userData]);

  const next = () => {
    if (validate()) {
      if (!termsAccepted) {
        setShowMustAcceptTerms(true);
        return;
      }
      context.setData({
        addressType,
        deliveryAddress: {
          first_name: form.name,
          last_name: form.lastName,
          country: form.countryOfResidence!,
          region: form.region,
          address: form.address,
          postal_code: form.postalCode,
          city: form.city,
          phone: form.phone
        },
        deliveryMethod: undefined
      })
      history.push('/new-card/shipping')
    }
  }

  if (loadingCardRequests || loadingCountries || loadingUserData) {
    return <Loading />
  }

  return (
    <>
      <ErrorDialog
        open={error}
        title={t('LOADING_ERROR_TITLE')}
        message={t('LOADING_ERROR_MESSAGE')}
        onClose={() => {
          setError(false);
          loadCardRequests();
          loadUserData();
          loadCountries();
        }}
      />
      <ErrorDialog
        open={showMustAcceptTerms}
        title={t('MUST_ACCEPT_TERMS_ERROR_TITLE')}
        message={t('MUST_ACCEPT_TERMS_ERROR_DESCRIPTION')}
        onClose={() => {
          setShowMustAcceptTerms(false);
        }}
      />
      <ErrorDialog
        open={cardError}
        title={t('ALREADY_ORDERED_CARD_ERROR_TITLE')}
        message={t('ALREADY_ORDERED_CARD_ERROR_MESSAGE')}
        onClose={() => {
          history.push('/dashboard');
        }}
      />
      <OrderWizard
        canGoNext={true}
        back={() => history.push('/dashboard')}
        loading={false}
        next={next}
        step={OrderWizardStep.DESTINATION}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' gutterBottom>
              {t('ADDRESS_TYPE')}
            </Typography>
            <Typography variant='body1'>
              {t('ADDRESS_TYPE_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <RadioGroup value={addressType} onChange={(e) => setAddressType(e.target.value as AddressType)}>
                <FormControlLabel value={AddressType.MYSELF} control={<Radio color='primary' />} label={t('TO_ME')} />
                <FormControlLabel value={AddressType.SOMEONE_ELSE} control={<Radio color='primary' />} label={t('TO_SOMEONE_ELSE')} />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('NAME')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth={true}
              label={t('NAME')}
              error={formErrors.name !== undefined}
              helperText={formErrors.name}
              variant='outlined'
              value={form.name}
              onChange={(event) => {
                setFormWithErrors({ form: { name: event.target.value }, errors: { name: undefined } });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth={true}
              label={t('LAST_NAME')}
              variant='outlined'
              value={form.lastName}
              error={formErrors.lastName !== undefined}
              helperText={formErrors.lastName}
              onChange={(event) => {
                setFormWithErrors({ form: { lastName: event.target.value }, errors: { lastName: undefined } });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('ADDRESS')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              onChange={(event, value) => {
                let phone = formWithErrors.form.phone;
                if (phone.length < 5 && value) {
                  phone = `+${value.prefix}`;
                }
                setFormWithErrors({ form: { countryOfResidence: value, phone, region: '' }, errors: { countryOfResidence: undefined } });
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              value={form.countryOfResidence}
              options={countries}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              getOptionLabel={(option) => option.name}
              renderOption={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('COUNTRY_OF_RESIDENCE')}
                  variant='outlined'
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'chrome-off',
                  }}
                  error={formErrors.countryOfResidence !== undefined}
                  helperText={formErrors.countryOfResidence}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth={true}
              label={t('STATE_OR_PROVINCE')}
              variant='outlined'
              value={form.region}
              error={formErrors.region !== undefined}
              helperText={formErrors.region}
              onChange={(event) => {
                setFormWithErrors({ form: { region: event.target.value }, errors: { region: undefined } });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth={true}
              label={t('ADDRESS')}
              variant='outlined'
              value={form.address}
              error={formErrors.address !== undefined}
              helperText={formErrors.address}
              onChange={(event) => {
                setFormWithErrors({ form: { address: event.target.value }, errors: { address: undefined } });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth={true}
              label={t('POSTAL_CODE')}
              variant='outlined'
              value={form.postalCode}
              error={formErrors.postalCode !== undefined}
              helperText={formErrors.postalCode}
              onChange={(event) => {
                setFormWithErrors({ form: { postalCode: event.target.value }, errors: { postalCode: undefined } });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth={true}
              label={t('CITY')}
              variant='outlined'
              value={form.city}
              error={formErrors.city !== undefined}
              helperText={formErrors.city}
              onChange={(event) => {
                setFormWithErrors({ form: { city: event.target.value }, errors: { city: undefined } });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('CONTACT_DATA')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth={true}
              label={t('PHONE')}
              variant='outlined'
              value={form.phone}
              error={formErrors.phone !== undefined}
              helperText={formErrors.phone}
              onChange={(event) => {
                setFormWithErrors({ form: { phone: event.target.value }, errors: { phone: undefined } });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox checked={termsAccepted} onChange={() => { setTermsAccepted(!termsAccepted) }} />}
              label={
                <>
                  <Trans t={t} i18nKey='TERMS_CONDITIONS'>
                    <Typography>
                      ACCEPT <a href='/terms-conditions' target='_blank'>TERMS+CONDITIONS</a>
                    </Typography>
                  </Trans>
                </>
              }
            />
          </Grid>
          
        </Grid>
      </OrderWizard>
    </>
  )
}