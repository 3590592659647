import React from 'react';
import { Topup } from "../services/types/topup.type";
import moment from 'moment';
import ClockIcon from '../assets/img/clock.svg';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    Typography,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import utils from '../services/utils.service';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        transactionContainer: {
            marginBottom: 10,
            borderRadius: 15,
            overflow: 'hidden',
            boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.2)',
        },
        transactionDescriptionContainer: {
            flexGrow: 1,
            padding: 10,
            paddingLeft: 20
        },
        transactionTimeContainer: {
            display: 'flex'
        },
        transactionTimeLabel: {
            marginLeft: 5,
            color: theme.palette.primary.main
        },
        transactionAmountContainer: {
            padding: 10,
            background: 'linear-gradient(28deg, #00407a 0%, #3c6aaa 55%, #7098dc 100%)',
        },
        transactionAmountBox: {
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: 'center'
        },
        transactionAmountLabel: {
            width: '100%',
            color: '#FFFFFF',
        }

    }),
);

export const TopupRow = ({ topup }: { topup: Topup }) => {
    const classes = useStyles();
    const { i18n, t } = useTranslation('card');
    moment.locale(i18n.language);
    const topupMoment = moment(topup.created);
    const theoricalApplicationDate = topupMoment.add(1, 'day').startOf('day');
    if (theoricalApplicationDate.day() === 6 || theoricalApplicationDate.day() === 0) {
        theoricalApplicationDate.add(1, 'weeks').startOf('isoWeek');
    }
    theoricalApplicationDate.add('18', 'hours');
    return (
        <Grid item xs={12} className={classes.transactionContainer}>
            <Grid container>
                <Grid item xs={9} className={classes.transactionDescriptionContainer}>
                    <Typography variant='body1'>
                        {t('TOPUP_DESCRIPTION', { date: theoricalApplicationDate.format('lll') })}
                    </Typography>
                    <div className={classes.transactionTimeContainer}>
                        <img src={ClockIcon} />
                        <Typography variant='body2' className={classes.transactionTimeLabel}>
                            {moment(topup.created).fromNow()}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={3} className={classes.transactionAmountContainer}>
                    <Box className={classes.transactionAmountBox}>
                        <Typography align='center' variant='h6' className={classes.transactionAmountLabel}>
                            {`${topup.amount > 0 ? '+' : '-'} ${utils.currencyFormat(topup.amount)} EUR`}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}