import React from 'react';
import { Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ErrorImg from '../assets/img/error.svg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: 200,
      maxWidth: '100%'
    },
  }),
);

export const PaymentKo = () => {

  const { t } = useTranslation('payment');
  const classes = useStyles();

  return (
    <>
      <Grid container alignContent='center' spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item>
              <img className={classes.image} src={ErrorImg} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' align='center' gutterBottom>
            {t('PAYMENT_ERROR_TITLE')}
          </Typography>
          <Typography variant='body1' align='center'>
            {t('PAYMENT_ERROR_DESCRIPTION')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item>
              <Button
                component={Link}
                to='/dashboard'
                variant='contained'
                color='primary'
                size='large'>
                {t('DASHBOARD')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  )

}