import { Box, Button, Checkbox, createStyles, FormControlLabel, Grid, IconButton, InputAdornment, TextField, Theme, Typography } from '@material-ui/core';
import { Phone, Visibility, VisibilityOff } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ErrorDialog } from '../components/errorDialog';
import { useApi, useAuth, useContext } from '../context';
import utils from '../services/utils.service';
import * as countriesJson from '../assets/countries.json';
import { OfflineCountry } from '../services/types/offlineCountry.type';
import { LoadingButton } from '../components/loadingButton';

type FormField = {
  value: string;
  errorState: boolean;
  helperText: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  option: {
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
}))

export const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation('login');

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [conceal, setConceal] = React.useState(true);

  const [keepSignedIn, setKeepSignedIn] = React.useState(true);
  const [selectedCountry, setSelectedCountry] = React.useState<OfflineCountry | null>(null);
  const [countries, setCountries] = React.useState<OfflineCountry[]>(countriesJson);

  const [form, updateForm] = React.useReducer((
    state: { [key: string]: FormField },
    action: { field: string, data: Partial<FormField> }
  ): { [key: string]: FormField } => {
    return {
      ...state,
      [action.field]: {
        ...state[action.field],
        ...action.data
      }
    };
  },
    {
      phone: {
        value: '',
        errorState: false,
        helperText: ''
      },
      pin: {
        value: '',
        errorState: false,
        helperText: ''
      },
    }
  );

  const api = useApi();
  const auth = useAuth();
  const context = useContext();
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const next = query.get('next');
  const token = query.get('token');

  React.useEffect(() => {
    if (token) {
      auth.setLoggedIn(token, false);
      if (next) {
        history.push(next);
      } else {
        history.push('/dashboard');
      }
    }

  }, []);

  const submit = () => {
    if (!validate()) {
      return;
    }
    utils.runAsync(async () => {
      setLoading(true);
      const loginResponse = await api.login(`+${utils.getOrDefault(selectedCountry?.DIAL)}${form.phone.value}`, form.pin.value);
      if (loginResponse.success) {
        auth.setLoggedIn(loginResponse.token!, keepSignedIn);
        if (next) {
          history.push(next);
        } else {
          history.push('/dashboard');
        }
      } else if (loginResponse.valid_credentials) {
        setError('NO_KYC');
      } else {
        setError('INVALID_CREDENTIALS');
      }
    }, (e) => {
      setLoading(false);
      if (e) {
        setError('GENERIC');
      }
    });
  }

  const validate = () => {
    let isValid = true;
    if (form.phone.value.length === 0) {
      isValid = false;
      updateForm({ field: 'phone', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    if (form.pin.value.length === 0) {
      isValid = false;
      updateForm({ field: 'pin', data: { errorState: true, helperText: t('REQUIRED') } });
    }
    return isValid;
  }

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateForm({ field: e.target.name, data: { value: e.target.value, errorState: false, helperText: '' } });
  }

  const toggleKeepSignedIn = () => {
    setKeepSignedIn(!keepSignedIn);
  }

  if (context.data.auth.isSignedIn) {
    return <Redirect to='/dashboard' />
  }

  return (
    <Box display='flex' width='100%' paddingTop={6} paddingBottom={6}>
      <ErrorDialog
        title={t('LOGIN_ERROR_TITLE')}
        message={t(utils.getOrDefault(error, 'GENERIC'))}
        open={error !== undefined}
        onClose={() => { setError(undefined) }} />
      <Grid container alignContent='center' spacing={4}>
        <Grid item xs={12}>
          <Typography variant='h3' align='center'>
            {t('LOGIN_TITLE')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' align='justify'>
            {t('LOGIN_DESCRIPTION')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                onChange={(event, value) => {
                  setSelectedCountry(value);
                }}
                value={selectedCountry}
                options={countries}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `+${option.DIAL} - ${option.NAME}`}
                getOptionSelected={(option, value) => option.ISO3 === value.ISO3}
                renderOption={(option) => (
                  <React.Fragment>
                    <CircleFlag countryCode={option.ISO2.toLowerCase()} height={25} cdnUrl="/assets/flags/" />
                    <span style={{ marginLeft: 10 }}>{`+${option.DIAL}`} - {option.NAME}</span>
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('PREFIX')}
                    variant='outlined'
                    fullWidth={true}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedCountry ? (
                        <CircleFlag countryCode={selectedCountry.ISO2.toLowerCase()} height={25} cdnUrl="/assets/flags/" />
                      ) : undefined
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label={t('PHONE')}
                value={form.phone.value}
                error={form.phone.errorState}
                helperText={form.phone.helperText}
                variant='outlined'
                name={'phone'}
                inputProps={{
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                }}
                fullWidth={true}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                label={t('PIN')}
                variant='outlined'
                inputProps={{
                  type: conceal ? 'password' : 'text',
                  pattern: '[0-9]*',
                  maxLength: 4,
                  inputMode: 'numeric',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => { setConceal(!conceal) }}
                      >
                        {conceal ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                name={'pin'}
                value={form.pin.value}
                error={form.pin.errorState}
                helperText={form.pin.helperText}
                onChange={handleFormChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={keepSignedIn}
                    onChange={toggleKeepSignedIn}
                    color='primary'
                  />
                }
                label={t('KEEP_ME_SIGNED_IN')}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <LoadingButton
                    onClick={submit}
                    loading={loading}
                    variant='contained'
                    color='primary'
                    size='large'>
                    {t('LOGIN')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

