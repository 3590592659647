import { Card } from "./card.type";

export enum CardRequestStatus {
  PENDING = 'PENDING',
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  SHIPPED = 'SHIPPED',
  CANCELLED = 'CANCELLED'
}

export type CardRequest = {
  id: number;
  status: CardRequestStatus;
  cards:Card[];
  tracking_details?: string;
}