{
  "LOADING_ERROR_TITLE": "Error!",
  "LOADING_ERROR_MESSAGE": "No hemos podido obtener la información solicitada. Vuelve a intentarlo.",
  "SETTINGS": "Ajustes",
  "LOGOUT": "Cerrar sesión",
  "CARD_PROCESSING_TITLE": "¡Estamos preparando tu pedido!",
  "CARD_PROCESSING_DESCRIPTION": "Estamos preparando tu tarjeta para ser enviada. Tan pronto como enviemos la tarjeta te mandaremos un correo electrónico con el seguimiento. Estamos teniendo un alto volumen de pedidos por lo que este proceso puede tardar unas semanas.",
  "CARD_SHIPPED_TITLE": "¡Tu tarjeta está de camino!",
  "CARD_SHIPPED_DESCRIPTION": "Tu tarjeta ha sido enviada y está de camino. Verifica tu buzón de correo electrónico para los detalles de seguimiento. Estamos preparando nuestra plataforma para que puedas empezar a administrar tu RevoluCARD Instant en breve.",
  "NEW_CARD_TITLE": "¡Todavía no tienes tarjetas RevoluCARD Instant!",
  "NEW_CARD_INTRO": "Puedes pedir una tarjeta ahora mismo. Las tarjetas RevoluCARD Instant tienen las siguientes características:",
  "REQUEST_NEW_CARD_BUTTON_LABEL": "¡Pedir ahora!",
  "ORDER_NEW_CARD": "Pedir tarjeta",
  "CARD_FEATURE_1": "Tarjeta prepago NO-nominativa vinculada al Wallet RevoluPay.",
  "CARD_FEATURE_2": "Simple y fácil de usar. No requiere de su activación.",
  "CARD_FEATURE_3": "Operativa a nivel nacional e internacional.",
  "CARD_FEATURE_4": "Máxima seguridad en sus compras online.",
  "CARD_FEATURE_5": "Vencimiento a fecha 12/24.",
  "CARD_FEATURE_6": "Comprobación de titularidad a través de la firma del usuario y código PIN.",
  "CARD_FEATURE_7": "Una única recarga de {{topupAmount}} EUR.",
  "CARD_FEATURE_7_DETAILS": "",
  "CARD_FEATURE_8": "El coste por tarjeta es de {{cardPrice}} EUR más gastos de envío.",
  "CARD_SELECTION_TITLE": "Tus tarjetas RevoluCARD Instant"
}