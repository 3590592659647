{
    "LOADING_ERROR_TITLE": "Error!",
    "LOADING_ERROR_MESSAGE": "We couldn't obtain the requested data. Please try again.",
    "SUBMIT_ERROR_TITLE": "Error!",
    "SUBMIT_ERROR_MESSAGE": "We couldn't process your request. Please try again.",
    "CONFIRM_TOPUP_TITLE": "Confirm topup",
    "CONFIRM_TOPUP_MESSAGE": "Your topup of {{amount}} EUR for card with MP {{cardMp}} will be applied on the next business day. In this case the topup will be applied no later than {{applicationDate}}. You can track your processing topups on the card overview page. Once you hit on CONFIRM we will direct you to the payment page.",
    "TOPUP_CARD": "Topup for card MP {{cardMp}}",
    "TOPUP_DESCRIPTION": "Please enter the topup amount and select the desired payment currency.",
    "AMOUNT": "Amount",
    "FEE": "Fee",
    "TOTAL": "Total due",
    "CANCEL": "CANCEL",
    "SUBMIT": "SUBMIT",
    "PAYMENT_CURRENCY": "Payment currency",
    "AMOUNT_IN_PAYMENT_CURRENCY": "Amount in payment currency",
    "MAX_SINGLE_TOPUP_AMOUNT_EXCEEDED": "The limit for a single topup is {{maxAmount}} EUR.",
    "INVALID_AMOUNT": "Please enter a valid amount.",
    "LIMIT_EXCEEDED": "Your remaining {{frequency}} topup limit is {{remainingAmount}} EUR.",
    "MONTHLY": "monthly",
    "QUARTERLY": "quarterly",
    "YEARLY": "yearly",
    "GLOBAL": "perpetual",
    "BACK_TO_CARD_DETAILS": "Back to card details"
}