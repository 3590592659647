import React, { Reducer, Dispatch } from 'react';
import { Api } from './services/api.service';
import { Config } from './config';
import { Auth } from './services/auth.service';
import { DeliveryAddress } from './services/types/deliveryAddress.type';
import { DeliveryMethod } from './services/types/deliveryMethod.type';
import { Currency } from './services/types/currency.type';
import { AddressType } from './newCard/destination';

const config = new Config();

type Data = {
  auth: {
    ready: boolean;
    isSignedIn: boolean;
  },
  addressType?:AddressType;
  deliveryAddress?: DeliveryAddress;
  deliveryMethod?:DeliveryMethod;
  cardNumber?:number;
  paymentCurrency?:Currency;
  cardPrice?:number
};

export type Context = {
  data: Data;
  setData: Dispatch<Partial<Data>>,
  reset: () => void
};

const VisaInstantContext = React.createContext<Context | undefined>(undefined);

export function useApi(): Api {
  const context = React.useContext(VisaInstantContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevolusendProvider');
  }
  return new Api(config, new Auth(context));
}

export function useAuth(): Auth {
  const context = React.useContext(VisaInstantContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevolusendProvider');
  }
  return new Auth(context);
}

export function useContext(): Context {
  const context = React.useContext(VisaInstantContext);
  if (context === undefined) {
    throw new Error('Use Store must be used inside of RevolusendProvider');
  }
  return context;
}

export const VisaInstantContextProvider = ({ children }: { children: any }) => {
  const reducer: Reducer<Data, Partial<Data>> = (state: Data, action: Partial<Data>) => {
    return {
      ...state,
      ...action
    };
  }

  const [data, setData] = React.useReducer(
    reducer,
    {
      auth: {
        ready: false,
        isSignedIn: false,
      },
    }
  );

  const reset = () => {
    setData({
      auth: {
        ready: data.auth.ready,
        isSignedIn: data.auth.isSignedIn,
      },
      addressType:undefined,
      deliveryAddress: undefined,
      deliveryMethod:undefined,
      cardNumber:undefined,
      paymentCurrency: undefined,
      cardPrice: undefined
    });
  }

  return (
    <VisaInstantContext.Provider value={{ data, setData, reset }}>
      {children}
    </VisaInstantContext.Provider>
  )
}
