import { Context } from '../context';
import moment from 'moment';
import * as jose from 'jose';

export class Auth {

  private accessToken?: string;

  constructor(
    private readonly context: Context,
  ) { }

  private setAccessToken(accessToken: string, store: boolean) {
    this.accessToken = accessToken;
    if (store) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      sessionStorage.setItem('accessToken', accessToken);
    }
  }

  public getAccessToken(): string {
    let accessToken: string | undefined;
    if (this.accessToken) {
      accessToken = this.accessToken;
    } else {
      if (localStorage.getItem('accessToken')) {
        accessToken = localStorage.getItem('accessToken')!;
      } else if (sessionStorage.getItem('accessToken')) {
        accessToken = sessionStorage.getItem('accessToken')!;
      }
    }
    if (accessToken && this.isAccessTokenValid(accessToken)) {
      return accessToken;
    } else if (accessToken) {
      this.logout();
    }
    throw new Error('No valid access token');
  }

  public attemptNonInteractiveLogin() {
    let accessToken: string | undefined;
    if (localStorage.getItem('accessToken')) {
      accessToken = localStorage.getItem('accessToken')!;
    }else if (sessionStorage.getItem('accessToken')) {
      accessToken = sessionStorage.getItem('accessToken')!;
    }
    if (accessToken && this.isAccessTokenValid(accessToken)) {
      this.accessToken = accessToken;
      this.context.setData({ auth: { ready: true, isSignedIn: true } });
    } else {
      this.context.setData({ auth: { ready: true, isSignedIn: false } });
    }
  }

  public setLoggedIn(accessToken: string, store: boolean) {
    this.setAccessToken(accessToken,store);
    this.context.setData({ auth: { ready: true, isSignedIn: true } });
  }

  public logout() {
    this.accessToken = undefined;
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('accessToken');
    this.context.setData({ auth: { ready: true, isSignedIn: false } });
  }

  private isAccessTokenValid(accessToken: string): boolean {
    try {
      const exp = jose.decodeJwt(accessToken).exp;
      if (!exp) return false;
      return moment(exp * 1000).isAfter(moment());
    } catch (e) {
      return false;
    }
  }

}