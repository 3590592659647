{
    "LOADING_ERROR_TITLE": "¡Error!",
    "LOADING_ERROR_MESSAGE": "No hemos podido obtener la información solicitada. Vuelve a intentarlo.",
    "CANCELLED": "CANCELADA",
    "EXPIRY_DATE_LABEL": "caducidad",
    "TOPUP": "Recargar",
    "REQUEST_PIN": "Solicitar pin",
    "BLOCK_CARD": "Cancelar tarjeta",
    "BALANCE": "Saldo",
    "PROCESSING_TOPUPS": "Recargas en proceso",
    "RECENT_TRANSACTIONS": "Últimas transacciones",
    "VIEW_ALL": "Ver todas",
    "LIMITS": "Límites de recarga",
    "RESETS_ON": "Se renueva el {{date}}",
    "TOPUP_DESCRIPTION": "Se aplica antes del {{date}}",
    "TRANSACTIONS_PLACEHOLDER_DESCRIPTION": "Esta tarjeta todavía no tiene transacciones.",
    "VIEW_TRANSACTIONS": "Revisar transacciones",
    "BACK_TO_CARD_SELECTOR": "Seleccionar otra tarjeta",
    "REQUEST_PIN_CONFIRM_DIALOG_TITLE": "¿Quieres solicitar el PIN de tu tarjeta?",
    "REQUEST_PIN_CONFIRM_DIALOG_DESCRIPTION": "Una vez pulses el botón de confirmar te enviaremos un SMS al teléfono vinculado a tu wallet de RevoluPAY con el PIN de la tarjeta.",
    "REQUEST_PIN_SUCCESS_DIALOG_TITLE": "¡Operación completada con éxito!",
    "REQUEST_PIN_SUCCESS_DIALOG_DESCRIPTION": "Hemos enviado un SMS con el PIN de tu tarjeta al teléfono vinculado a tu wallet de RevoluPay.",
    "BLOCK_CARD_CONFIRM_DIALOG_TITLE": "¿Seguro que quieres cancelar la tarjeta?",
    "BLOCK_CARD_CONFIRM_DIALOG_DESCRIPTION": "Si cancelas la tarjeta ya no se podrá usar en ningún comercio y el saldo que hay en ella se bloqueará.",
    "BLOCK_CARD_SUCCESS_DIALOG_TITLE": "¡Operación completada con éxito!",
    "BLOCK_CARD_SUCCESS_DIALOG_DESCRIPTION": "¡La tarjeta ha sido cancelada! Te enviaremos otro correo con la confirmación de la cancelación de la tarjeta.",
    "ACTIONS": "ACCIONES",
    "QUARTERLY": "TRIMESTRAL",
    "MONTHLY": "MENSUAL",
    "YEARLY": "ANUAL",
    "GLOBAL": "PERPETUO",
    "BALANCE_INFO_TITLE": "Información importante acerca del saldo de su tarjeta",
    "BALANCE_INFO_MESSAGE": "El saldo de tu tarjeta no se actualiza en tiempo real y por lo tanto el saldo mostrado puede diferir con el saldo real de la tarjeta.",
    "BACK_TO_CARD_DETAILS": "Volver a detalles de tarjeta",
    "CARD_TRANSACTION_LIST_TITLE": "Transacciones de la tarjeta MP {{cardMp}}",
    "DETAILED_TRANSACTIONS_PLACEHOLDER_DESCRIPTION": "Actualmente no hay resultados para los criterios de búsqueda.",
    "LOAD_MORE": "Cargar más",
    "FILTER_TRANSACTIONS": "Filtrar transacciones",
    "LAST_30_DAYS": "Transacciones creadas en los últimos 30 días",
    "TXTOPUP": "Recarga de tarjeta"
}