import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useApi, useAuth, useContext } from '../context'
import { useTranslation } from 'react-i18next';
import { Loading } from '../components/loading';
import utils from '../services/utils.service';
import { ErrorDialog } from '../components/errorDialog';
import { SpeedDial } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { CardRequest, CardRequestStatus } from '../services/types/cardRequest.type';
import { CardSelector } from './cardSelector';
import { ProcessingCardRequest } from './processingCardRequest';
import { NoCardRequests } from './noCardRequests';
import { useHistory } from 'react-router-dom';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { Settings } from '../services/types/settings.type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },

  }),
);

export const Dashboard = () => {
  const { t } = useTranslation('dashboard');
  const context = useContext();
  const api = useApi();
  const auth = useAuth();
  const classes = useStyles();
  const history = useHistory();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [cardRequests, setCardRequests] = React.useState<CardRequest[]>([]);
  const [settings, setSettings] = React.useState<Settings|undefined>();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const loadCardRequests = () => {
    utils.runAsync(async () => {
      setLoading(true);
      setSettings(await api.getSettings());
      setCardRequests(await api.getCardRequests());
    }, (e) => {
      setLoading(false);
      if (e) {
        setError(true);
      }
    });
  }

  React.useEffect(() => {
    context.reset();
    loadCardRequests();
  }, []);


  if (loading) {
    return <Loading />
  }

  const getView = () => {
    const shippedCard = cardRequests.find(c => c.status === CardRequestStatus.SHIPPED);
    const processingCard = cardRequests.find(c => c.status === CardRequestStatus.NEW || c.status === CardRequestStatus.PROCESSING);
    if (shippedCard) {
      return <CardSelector/>
    } else if (processingCard) {
      return <ProcessingCardRequest />
    } else {
      return <NoCardRequests settings={settings!!}/>
    }
  }

  return (
    <Box paddingBottom={12} display='flex' flexGrow={1}>
      <SpeedDial
        ariaLabel={t('SETTINGS')}
        direction={'up'}
        FabProps={{
          color: 'primary',
          size:'medium'
        }}
        className={classes.speedDial}
        icon={<SettingsIcon />}
        onClose={() => setMenuOpen(false)}
        onOpen={() => setMenuOpen(true)}
        open={menuOpen}
      >
        <SpeedDialAction
          icon={<ExitToAppIcon />}
          tooltipTitle={t('LOGOUT')}
          tooltipOpen
          onClick={() => {
            setMenuOpen(false);
            auth.logout();
          }}
        />
        <SpeedDialAction
          icon={<AddShoppingCartIcon />}
          tooltipTitle={t('ORDER_NEW_CARD')}
          tooltipOpen
          onClick={() => {
            setMenuOpen(false);
            history.push('/new-card')
          }}
        />
      </SpeedDial>
      <ErrorDialog
        open={error}
        title={t('LOADING_ERROR_TITLE')}
        message={t('LOADING_ERROR_MESSAGE')}
        onClose={() => {
          setError(false);
          loadCardRequests();
        }}
      />
      {getView()}
    </Box>
  )
}