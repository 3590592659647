import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ProcessingCardImg from '../assets/img/processing.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: 400,
      maxWidth: '100%'
    },
  }),
);

export const ProcessingCardRequest = () => {

  const { t } = useTranslation('dashboard');
  const classes = useStyles();

  return (
    <>
      <Grid container alignContent='center' spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item>
              <img className={classes.image} src={ProcessingCardImg} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' align='center' gutterBottom>
            {t('CARD_PROCESSING_TITLE')}
          </Typography>
          <Typography variant='body1' align='center'>
            {t('CARD_PROCESSING_DESCRIPTION')}
          </Typography>
        </Grid>
      </Grid>

    </>
  )

}