import React from 'react';
import moment from 'moment';
import ClockIcon from '../assets/img/clock.svg';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    Typography,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import { Transaction } from '../services/types/transaction.type';
import utils from '../services/utils.service';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        transactionContainer: {
            marginBottom: 10,
            borderRadius: 15,
            overflow: 'hidden',
            boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.2)',
        },
        transactionDescriptionContainer: {
            flexGrow: 1,
            padding: 10,
            paddingLeft: 20
        },
        transactionTimeContainer: {
            display: 'flex'
        },
        transactionTimeLabel: {
            marginLeft: 5,
            color: theme.palette.primary.main
        },
        positiveTransactionAmountContainer: {
            padding: 10,
            background: 'linear-gradient(28deg, #00407a 0%, #3c6aaa 55%, #7098dc 100%)',
        },
        negativeTransactionAmountContainer: {
            padding: 10,
            background: 'linear-gradient(28deg, #8a0008 0%, #c2192f 55%, #fb5658 100%)',
        },
        transactionAmountBox: {
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: 'center'
        },
        transactionAmountLabel: {
            width: '100%',
            color: '#FFFFFF',
        }

    }),
);

export const TransactionRow = ({ transaction }: { transaction: Transaction }) => {
    const classes = useStyles();
    const { i18n,t } = useTranslation('card');
    moment.locale(i18n.language);
    return (
        <Grid item xs={12} className={classes.transactionContainer}>
            <Grid container>
                <Grid item xs={9} className={classes.transactionDescriptionContainer}>
                    <Typography variant='body1'>
                        {`${t(transaction.description.replace('TOPUP','TXTOPUP'))}`}
                    </Typography>
                    <div className={classes.transactionTimeContainer}>
                        <img src={ClockIcon} />
                        <Typography variant='body2' className={classes.transactionTimeLabel}>
                            {moment(transaction.created).format('ll')}
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={3} className={transaction.amount>0?classes.positiveTransactionAmountContainer:classes.negativeTransactionAmountContainer}>
                    <Box className={classes.transactionAmountBox}>
                        <Typography align='center' variant='h6' className={classes.transactionAmountLabel}>
                            {`${transaction.amount > 0 ? '+' : '-'} ${utils.currencyFormat(Math.abs(transaction.amount) * transaction.exchange_rate)} EUR`}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}