{
  "LOADING_ERROR_TITLE": "Error!",
  "LOADING_ERROR_MESSAGE": "No hemos podido obtener la información solicitada. Inténtalo de nuevo.",
  "ALREADY_ORDERED_CARD_ERROR_TITLE": "¡Ya has pedido una tarjeta!",
  "ALREADY_ORDERED_CARD_ERROR_MESSAGE": "Actualmente sólo se permite una tarjeta por usuario. En el futuro permitiremos más tarjetas por usuario. ¡Te mantendremos informado!",
  "ORDER_NEW_CARD": "Pedir una tarjeta RevoluCARD Instant",
  "DESTINATION": "Destino",
  "DELIVERY": "Método de envío",
  "CHECKOUT": "Checkout",
  "BACK": "Atrás",
  "NEXT": "Siguiente",
  "CANCEL": "Cancelar",
  "TO_ME": "A mí",
  "TO_SOMEONE_ELSE": "A otra persona",
  "NAME": "Nombre",
  "LAST_NAME": "Apellidos",
  "STATE_OR_PROVINCE": "Estado o provincia",
  "ADDRESS": "Dirección",
  "POSTAL_CODE": "Código postal",
  "CITY": "Ciudad",
  "CONTACT_DATA": "Detalles de contacto",
  "PHONE": "Teléfono",
  "COUNTRY_OF_RESIDENCE": "País",
  "ADDRESS_TYPE": "¿A quién deberíamos enviar la tarjeta?",
  "ADDRESS_TYPE_DESCRIPTION": "Incluso si decides enviar la tarjeta a otra persona serás tú quien la administre y estará vinculada a tu cuenta RevoluPAY.",
  "INVALID_PHONE": "Teléfono inválido. Introduce el teléfono con el prefijo internacional. Por ejemplo: +10123456789.",
  "REQUIRED": "Este campo es obligatorio",
  "PAYMENT_CURRENCY_DESCRIPTION": "Selecciona cuantas tarjetas quieres pedir y la moneda de pago del pedido.",
  "CARD_AMOUNT": "Cantidad de tarjetas",
  "CARD_NUMBER": "Cantidad de tarjetas",
  "PAYMENT_CURRENCY": "Moneda de pago",
  "SHIPPING_METHOD_TITLE": "Selecciona un método de envío",
  "SUMMARY": "Resumen de pedido",
  "NO_DELIVERY_METHODS_AVAILABLE": "Actualmente no podemos enviar la tarjeta al país seleccionado. Inténtalo en otro momento.",
  "CARD_ISSUE_FEE": "Emisión de tarjeta",
  "SHIPPING_FEE": "Costes de envío",
  "TOTAL": "Total",
  "FINISH": "Enviar",
  "SUBMIT_ERROR_TITLE": "Error!",
  "SUBMIT_ERROR_MESSAGE": "No hemos podido crear tu pedido. Inténtalo de nuevo más tarde. Si el error persiste envía una solicitud a través del centro de ayuda.",
  "CHECKOUT_INTRO": "Verifica los detalles del pedido y cuando estés listo pulsa en Enviar.",
  "DELIVERY_ADDRESS": "Dirección de envío",
  "DELIVERY_METHOD": "Método de envío",
  "PAYMENT": "Resumen del pedido",
  "TERMS_CONDITIONS": "<0>Acepto los <1>términos y condiciones</1>.</0>",
  "MUST_ACCEPT_TERMS_ERROR_TITLE": "Términos y condiciones no aceptados",
  "MUST_ACCEPT_TERMS_ERROR_DESCRIPTION": "Para solicitar una tarjeta debes aceptar los términos y condiciones del servicio.",
  "MAX_LENGTH": "El contenido del campo es demasiado largo.",
  "NO_ONLINE_PAYMENTS_WARNING": "Aviso: Las tarjetas RevoluCARD Instant no soportan pagos online."
}