{
    "LOADING_ERROR_TITLE": "Error!",
    "LOADING_ERROR_MESSAGE": "We couldn't obtain the requested data. Please try again.",
    "CANCELLED": "CANCELLED",
    "EXPIRY_DATE_LABEL": "valid thru",
    "TOPUP": "Topup",
    "REQUEST_PIN": "Request PIN",
    "BLOCK_CARD": "Cancel card",
    "BALANCE": "Balance",
    "PROCESSING_TOPUPS": "Processing Topups",
    "RECENT_TRANSACTIONS": "Recent Transactions",
    "VIEW_ALL": "View all",
    "LIMITS": "Topup Limits",
    "RESETS_ON": "Resets on {{date}}",
    "TOPUP_DESCRIPTION": "Will be applied before {{date}}",
    "TRANSACTIONS_PLACEHOLDER_DESCRIPTION": "This card does not have any transactions yet.",
    "VIEW_TRANSACTIONS": "Review transactions",
    "BACK_TO_CARD_SELECTOR": "Select a different card",
    "REQUEST_PIN_CONFIRM_DIALOG_TITLE": "Would you like to request your card PIN now?",
    "REQUEST_PIN_CONFIRM_DIALOG_DESCRIPTION": "Once you press on confirm we will send you the PIN code via SMS to the phone number that you use to login to the RevoluPay APP.",
    "REQUEST_PIN_SUCCESS_DIALOG_TITLE": "Success!",
    "REQUEST_PIN_SUCCESS_DIALOG_DESCRIPTION": "We have sent you the PIN code to the phone number that you use to login to your RevoluPay account via SMS. It should arrive shortly!",
    "BLOCK_CARD_CONFIRM_DIALOG_TITLE": "Do you really want to cancel this card?",
    "BLOCK_CARD_CONFIRM_DIALOG_DESCRIPTION": "If you cancel this card it can't be used again and the balance on it will be blocked. Are you sure you want to proceed?",
    "BLOCK_CARD_SUCCESS_DIALOG_TITLE": "Success!",
    "BLOCK_CARD_SUCCESS_DIALOG_DESCRIPTION": "The card was cancelled! You will receive an e-mail shortly with the confirmation. This card can no longer be used and it's balance was blocked.",
    "ACTIONS": "ACTIONS",
    "QUARTERLY": "QUARTERLY",
    "MONTHLY": "MONTHLY",
    "YEARLY": "YEARLY",
    "GLOBAL": "PERPETUAL",
    "BALANCE_INFO_TITLE": "Important information about your card's balance",
    "BALANCE_INFO_MESSAGE": "You card's balance is not updated in real time and therefore might not represent the cards current balance.",
    "BACK_TO_CARD_DETAILS": "Back to card details",
    "CARD_TRANSACTION_LIST_TITLE": "Transactions for card MP {{cardMp}}",
    "DETAILED_TRANSACTIONS_PLACEHOLDER_DESCRIPTION": "There are currently no results matching your search criteria.",
    "LOAD_MORE": "Load more",
    "FILTER_TRANSACTIONS": "Filter transactions",
    "LAST_30_DAYS": "Transactions created during last 30 days",
    "TXTOPUP": "Card Topup"
}