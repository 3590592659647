import React from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const Terms = () => {
  const { i18n } = useTranslation();
  const ref: any = React.useRef();
  const [height, setHeight] = React.useState("0px");

  const updateHeight = () => {
    setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
  }


  return (
    <>
      <iframe
        ref={ref}
        onLoad={updateHeight}
        width={'100%'}
        height={height}
        scrolling={'no'}
        frameBorder={'0'}
        src={i18n.language === 'es' ? '/assets/terms/es.htm' : '/assets/terms/en.htm'} />
    </>
  )
}