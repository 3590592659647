import React from 'react';
import { Dialog, Grid, Box, Typography, Button } from '@material-ui/core';
import alertImg from '../assets/img/alert-icon.svg'
import { useTranslation } from 'react-i18next';
import { LoadingButton } from './loadingButton';

export const ConfirmDialog = ({
  open,
  onCancel,
  onConfirm,
  title,
  loading,
  message
}: {
  open: boolean,
  onCancel: () => void,
  onConfirm: () => void,
  title: string,
  loading?:boolean,
  message?: string
}) => {
  const { t } = useTranslation('layout');
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      onClose={onCancel}
      aria-labelledby='alert-dialog'
    >
      <Box padding={4}>
        <Grid container spacing={8}>
          <Grid container item justifyContent='center'>
            <img src={alertImg} width='150' />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h4' align='center'>
                  {title}
                </Typography>
              </Grid>
              {message &&
                <Grid item xs={12}>
                  <Typography variant='body1' align='justify'>
                    {message}
                  </Typography>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent='center'>
            <Grid container item xs={6} justifyContent='center'>
              <Button onClick={onCancel} size='large'>{t('CANCEL')}</Button>
            </Grid>
            <Grid container item xs={6} justifyContent='center'>
              <LoadingButton loading={loading} onClick={onConfirm} size='large' color='secondary'>{t('CONFIRM')}</LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}
