import { CircularProgress, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const Loading = () => {
  const classes = useStyles();
  const { t } = useTranslation('layout');
  return (
    <Grid container alignItems='center'>
      <Grid item container alignContent='center' alignItems='center' direction='column'>
        <Grid item>
          <CircularProgress color='primary' size={60} />
        </Grid>
        <Grid item>
          <Typography variant='h4' className={ classes.title}>
            {t('LOADING')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}