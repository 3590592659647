{
    "LOADING_ERROR_TITLE": "¡Error!",
    "LOADING_ERROR_MESSAGE": "No hemos podido obtener la información solicitada. Vuelve a intentarlo.",
    "SUBMIT_ERROR_TITLE": "¡Error!",
    "SUBMIT_ERROR_MESSAGE": "No hemos podido procesar tu solicitud. Vuelve a probarlo más tarde.",
    "CONFIRM_TOPUP_TITLE": "Confirma la recarga",
    "CONFIRM_TOPUP_MESSAGE": "Tu recarga de {{amount}} EUR para la tarjeta con MP {{cardMp}} se aplicará el siguiente día hábil. En este caso la recarga se aplicará no más tarde que el {{applicationDate}}. Puedes seguir el estado de tu recarga desde la vista detallada de la tarjeta. Una vez pulses sobre CONFIRMAR te redireccionaremos a la pantalla de pago.",
    "TOPUP_CARD": "Recarga de la tarjeta MP {{cardMp}}",
    "TOPUP_DESCRIPTION": "Introduce el importe que quieres recarga y selecciona tu moneda de pago deseada.",
    "AMOUNT": "Importe",
    "FEE": "Comisión",
    "TOTAL": "Total a pagar",
    "CANCEL": "CANCELAR",
    "SUBMIT": "ENVIAR",
    "PAYMENT_CURRENCY": "Moneda de pago",
    "AMOUNT_IN_PAYMENT_CURRENCY": "Importe en moneda de pago",
    "MAX_SINGLE_TOPUP_AMOUNT_EXCEEDED": "El límite por recarga es de {{maxAmount}} EUR.",
    "INVALID_AMOUNT": "Introduce un importe válido.",
    "LIMIT_EXCEEDED": "Tu limite {{frequency}} de recarga restante es de {{remainingAmount}} EUR.",
    "MONTHLY": "mensual",
    "QUARTERLY": "trimestral",
    "YEARLY": "anual",
    "GLOBAL": "perpetuo",
    "BACK_TO_CARD_DETAILS": "Volver a detalles de tarjeta"
}