{
  "LOGIN_TITLE": "Inicia sesión para continuar",
  "LOGIN_DESCRIPTION": "Inicia sesión con tu cuenta RevoluPAY para pedir y administrar tarjetas RevoluCARD Instant.",
  "LOGIN_ERROR_TITLE": "Ups! Algo no ha ido bien",
  "GENERIC": "No hemos podido completar la acción. Inténtalo más tarde",
  "PREFIX": "Prefijo",
  "PHONE": "Teléfono",
  "PIN": "PIN",
  "KEEP_ME_SIGNED_IN": "Mantener la sesión iniciada",
  "LOGIN": "Enviar",
  "REQUIRED": "Este campo es obligatorio",
  "INVALID_CREDENTIALS": "No hemos podido iniciar tu sesión. Por favor verifica tus credenciales e inténtalo de nuevo.",
  "NO_KYC": "No hemos podido iniciar tu sesión. Por favor verifica que hayas pasado el proceso de KYC en la APP de RevoluPAY e inténtalo de nuevo."
}