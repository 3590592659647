import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route as DomRoute,
  useLocation,
  Redirect
} from 'react-router-dom';

import { MainLayout } from './layout/main';
import { useAuth } from './context';
import { Login } from './login/login';
import { Dashboard } from './dashboard/dasboard';
import { NewCardDestination } from './newCard/destination';
import { Terms } from './static/terms';
import { NewCardShipping } from './newCard/shipping';
import { NewCardCheckout } from './newCard/checkout';
import { PaymentCheck } from './payment/paymentCheck';
import { PaymentOk } from './payment/paymentOk';
import { PaymentKo } from './payment/paymentKo';
import { CardOverview } from './cards/cardOverview';
import { Topup } from './cards/topup';
import { Transactions } from './cards/transactions';


const PublicRoute = ({
  component: Component,
  ...rest
}: {
  component: any,
  exact?: boolean,
  path: string[] | string
}) => (
  <DomRoute
    {...rest}
    render={(props) => (
      <MainLayout allowNoAuth={true}>
        <Component {...props} />
      </MainLayout>
    )} />
)


const PrivateRoute = ({
  component: Component,
  blueBackground,
  noPadding,
  allowNoAuth,
  ...rest
}: {
  component: any,
  blueBackground?: boolean,
  noPadding?: boolean,
  allowNoAuth?: boolean,
  exact?: boolean,
  path: string[] | string
}) => (
  <DomRoute
    {...rest}
    render={(props) => (
      <MainLayout allowNoAuth={false}>
        <Component {...props} />
      </MainLayout>
    )} />
)

const ScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);

  }, [pathname]);
  return null;
}

export const Router = () => {
  const auth = useAuth();
  React.useEffect(() => {
    auth.attemptNonInteractiveLogin();
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <PublicRoute exact path='/terms-conditions' component={Terms} />
        <PublicRoute exact path='/login' component={Login} />
        <PrivateRoute exact path='/dashboard' component={Dashboard} />
        <PrivateRoute exact path='/cards/:cardId' component={CardOverview} />
        <PrivateRoute exact path='/cards/:cardId/topup/amount' component={Topup} />
        <PrivateRoute exact path='/cards/:cardId/topup' component={Topup} />
        <PrivateRoute exact path='/cards/:cardId/transactions' component={Transactions} />
        <PrivateRoute exact path='/new-card/destination' component={NewCardDestination} />
        <PrivateRoute exact path='/new-card' component={() => <Redirect to='/new-card/destination' />} />
        <PrivateRoute exact path='/new-card/shipping' component={NewCardShipping} />
        <PrivateRoute exact path='/new-card/checkout' component={NewCardCheckout} />
        <PrivateRoute exact path='/payments/check' component={PaymentCheck} />
        <PublicRoute exact path='/payments/ok' component={PaymentOk} />
        <PublicRoute exact path='/payments/ko' component={PaymentKo} />
        <Redirect to='/login' />
      </Switch>
    </BrowserRouter>
  );
}
