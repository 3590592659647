{
  "LOGIN_TITLE": "Please log in to continue",
  "LOGIN_DESCRIPTION": "Please log in to your RevoluPAY account in order to manage and request RevoluCARD Instant cards.",
  "LOGIN_ERROR_TITLE": "Ups! Something went wrong",
  "GENERIC": "We could not complete that action. Please try again later.",
  "PREFIX": "Country code",
  "PHONE": "Phone number",
  "PIN": "PIN",
  "KEEP_ME_SIGNED_IN": "Keep me signed in",
  "LOGIN": "Submit",
  "REQUIRED": "This field is mandatory.",
  "INVALID_CREDENTIALS": "We could not log you in. Please verify your credentials and try again.",
  "NO_KYC": "We could not log you in. Please verify that you have completed the KYC process within the RevoluPAY app and try again later. If this error persis please open a support request over the Help Center."
}