import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as layoutEn from './assets/locale/en/layout.json'
import * as layoutEs from './assets/locale/es/layout.json'
import * as loginEn from './assets/locale/en/login.json'
import * as loginEs from './assets/locale/es/login.json'
import * as dashboardEn from './assets/locale/en/dashboard.json'
import * as dashboardEs from './assets/locale/es/dashboard.json'
import * as newCardEn from './assets/locale/en/newCard.json'
import * as newCardEs from './assets/locale/es/newCard.json'
import * as paymentEn from './assets/locale/en/payment.json'
import * as paymentEs from './assets/locale/es/payment.json'
import * as cardEn from './assets/locale/en/card.json'
import * as cardEs from './assets/locale/es/card.json'
import * as topupEn from './assets/locale/en/topup.json'
import * as topupEs from './assets/locale/es/topup.json'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        layout: layoutEn,
        login: loginEn,
        dashboard: dashboardEn,
        newCard: newCardEn,
        payment: paymentEn,
        card: cardEn,
        topup: topupEn
      },
      es: {
        layout: layoutEs,
        login: loginEs,
        dashboard: dashboardEs,
        newCard: newCardEs,
        payment: paymentEs,
        card: cardEs,
        topup: topupEs
      }
    },
    supportedLngs: ['en', 'es'],
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;