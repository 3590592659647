import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import RevolupayLogo from '../assets/img/revolupay-logo-light.svg';
import { useContext } from '../context';
import { Redirect, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    },
    header: {
      backgroundColor: theme.palette.secondary.main,
      height: 135,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    logo: {
      width: 320,
      cursor: 'pointer'
    },
    logoWrapper: {
      position: 'relative'
    },
    logoSubtitle: {
      position: 'absolute',
      bottom: 10,
      right: 15,
      color: '#FFFFFF'
    },
    container: {
      display: 'flex',
      flexGrow: 1,
      position:'relative',
      padding:theme.spacing(2)
    },
    footer: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(4)
    },
    subFooter: {
      backgroundColor: theme.palette.primary.dark,
      padding: theme.spacing(2)
    },
    footerLink: {
      color: 'white',
      fontWeight: 600
    },
    footerText: {
      color: theme.palette.secondary.contrastText
    }

  }),
);


export const MainLayout = ({ children, allowNoAuth }: { children: any, allowNoAuth: boolean }) => {
  const classes = useStyles();
  const context = useContext();
  const history = useHistory();

  const { i18n, t } = useTranslation('layout');

  if (!allowNoAuth) {
    if (context.data.auth.ready && !context.data.auth.isSignedIn) {
      return <Redirect to='/' />
    }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.header}>
        <div className={classes.logoWrapper}>
          <img onClick={()=>{history.push('/')}} className={classes.logo} src={RevolupayLogo} />
          <Typography variant='h6' className={classes.logoSubtitle}>
            {'RevoluCARD Instant'}
          </Typography>
        </div>
      </Box>
      <Container className={classes.container} maxWidth='md'>
        {children}
      </Container>
      <Box className={classes.footer}>
        <Grid container direction='row' spacing={2} justifyContent='center'>
          <Grid item>
            <Link className={classes.footerLink} target='_blank' href={i18n.language === 'es' ? 'https://revolupay.zendesk.com/hc/es/requests/new' : 'https://revolupay.zendesk.com/hc/en-us/requests/new'}>{t('HELP')}</Link>
          </Grid>
          <Grid item>
            <Link className={classes.footerLink} target='_blank' href='/terms-conditions'>{t('TERMS_CONDITIONS')}</Link>
          </Grid>
          <Grid item>
            <Link className={classes.footerLink} target='_blank' href='https://www.revolupay.es/privacy'>{t('PRIVACY')}</Link>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.subFooter}>
        <Grid container>
          <Grid item xs={12}>
            <Trans t={t} i18nKey='REVOLUPAY_INFO'>
              <Typography variant='body2' align='center' className={classes.footerText}>
                {'REVOLUPAY EP S.L. Payment Entity authorized and supervised by Central Bank of Spain and registered in the Institutions Register with code 6900. RevoluPAY is a Spanish company wholly owned by '}
                <Link
                  className={classes.footerText}
                  href='https://www.revolugroup.com'
                  rel='noopener'
                  target='_blank'
                >
                  {' REVOLUGROUP CANADA INC.'}
                </Link>
                {' a publicly traded company on the '}
                <Link
                  className={classes.footerText}
                  href='https://money.tmx.com/en/quote/REVO'
                  target='_blank'
                  rel='noopener'>
                  {'Toronto Stock Exchange - REVO'}
                </Link>
              </Typography>
            </Trans>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
};

