import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

export const LoadingButton = (props: ButtonProps & { loading?: boolean }) => {
  const classes = useStyles();
  let color = props.color;
  if (color === 'default') {
    color = undefined;
  }
  const { loading, ...rest } = props;
  return (
    <div className={classes.wrapper}>
      <Button {...rest} disabled={props.disabled || loading}>
        {props.children}
      </Button>
      {loading && <CircularProgress color={color} size={24} className={classes.buttonProgress} />}
    </div>
  );
}
