import React from 'react';
import { Grid, Typography, CircularProgress, Container, Link } from '@material-ui/core';
import { useApi } from '../context';
import { useHistory, Redirect } from 'react-router-dom';
import utils from '../services/utils.service';
import { QRCode } from 'react-qr-svg';
import { useTranslation } from 'react-i18next';

export const PaymentCheck = () => {
  const { t } = useTranslation('payment');
  const api = useApi();
  const history = useHistory();


  const query = new URLSearchParams(window.location.search);
  const revolupayOrderId = query.get('id');
  const revolupayOrderReference = query.get('code');

  if (!revolupayOrderId || !revolupayOrderReference) {
    return (
      <Redirect to='/' />
    )
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      utils.runAsync(async () => {
        const hasOrderBeenPaid = await api.hasRevoluPAYOrderBeenPaid(revolupayOrderId);
        if (hasOrderBeenPaid) {
          history.push('/payments/ok');
        }
      });
    }, 2500);
    return () => {
      clearInterval(interval);
    }
  }, []);

  return (
    <Grid container alignContent='center' spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h3' align='center'>
          {t('PAYMENT_TITLE')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom align='center'>
          {t('PAYMENT_DESCRIPTION')}
        </Typography>
      </Grid>
      <Grid item xs={12} container justify='center'>
        <CircularProgress />
      </Grid>
      <Grid item xs={12} container justify='center'>
        <QRCode value={revolupayOrderReference} style={{ width: 250 }} />
      </Grid>
      <Grid item xs={12} container justify='center'>
        <Link
          href={`https://www.revolupay.com/pnp?action=complete_order&order_reference=${revolupayOrderReference}`}
          target='_blank'
          rel='noopener'>
          {t('PAY_IN_APP')}
        </Link>
      </Grid>
      <Grid item xs={12}>
        <Typography align='center'>
          {t('PAGE_WILL_REFRESH')}
        </Typography>
      </Grid>
    </Grid>
  );
}