export class Config {

  readonly BASE_URL?: string;
  readonly API_BASE_URL: string;
  readonly ALLOW_MULTIPLE_CARDS: boolean;

  constructor() {
    this.BASE_URL = process.env.BASE_URL ? process.env.BASE_URL : '';
    this.API_BASE_URL = process.env.API_BASE_URL ? process.env.API_BASE_URL : '';
    this.ALLOW_MULTIPLE_CARDS = true;
  }
}
