import React from 'react';
import { Dialog, Grid, Box, Typography, Button } from '@material-ui/core';
import errorImg from '../assets/img/error.svg'

export const ErrorDialog = ({
  open,
  onClose,
  title,
  message
}: {
  open: boolean,
  onClose: () => void,
  title: string
  message?: string
}) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='md'
      onClose={onClose}
      aria-labelledby='alert-dialog'
    >
      <Box padding={4}>
        <Grid container spacing={8}>
          <Grid container item justifyContent='center'>
            <img src={errorImg} width='100' />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h4' align='center'>
                  {title}
                </Typography>
              </Grid>
              {message &&
                <Grid item xs={12}>
                  <Typography variant='body1' align='justify'>
                    {message}
                  </Typography>
                </Grid>
              }
            </Grid>
          </Grid>
          <Grid container item xs={12} justifyContent='center'>
            <Button onClick={onClose} size='large' color='secondary'>{'OK'}</Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog >
  )
}
