{
  "LOADING_ERROR_TITLE": "Error!",
  "LOADING_ERROR_MESSAGE": "We couldn't obtain the requested data. Please try again.",
  "SETTINGS": "Settings",
  "LOGOUT": "Logout",
  "CARD_PROCESSING_TITLE": "We are preparing your order!",
  "CARD_PROCESSING_DESCRIPTION": "We are currently getting your card ready. As soon as we ship your card you will receive an e-mail with the tracking details. This might take up to a few weeks as we are currently experiencing a large amount of orders.",
  "CARD_SHIPPED_TITLE": "Your card is on the way!",
  "CARD_SHIPPED_DESCRIPTION": "Your card was shipped and is on the way to you. You should have received an e-mail address with the tracking details of the shipment. We are currently getting ready our platform for you to manage and topup your RevoluCARD Instant. You're all set and we will notify you once we are ready for you to topup and manage your RevoluCARD Instant.",
  "NEW_CARD_TITLE": "You don't have any cards yet!",
  "NEW_CARD_INTRO": "You can order a new RevoluCARD Instant right now. RevoluCARD Instant have following features:",
  "REQUEST_NEW_CARD_BUTTON_LABEL": "Order now!",
  "ORDER_NEW_CARD": "Order card",
  "CARD_FEATURE_1": "Anonymous card linked to your RevoluPay Wallet.",
  "CARD_FEATURE_2": "Simple and easy to use. Does not require activation.",
  "CARD_FEATURE_3": "Works locally and international.",
  "CARD_FEATURE_4": "Secure online purchases.",
  "CARD_FEATURE_5": "Expiration date 12/24.",
  "CARD_FEATURE_6": "Id verification by signature and PIN code.",
  "CARD_FEATURE_7": "One single recharge of {{topupAmount}} EUR.",
  "CARD_FEATURE_7_DETAILS": "",
  "CARD_FEATURE_8": "The issue fee for the card is {{cardPrice}} EUR plus shipping costs.",
  "CARD_SELECTION_TITLE": "Your RevoluCARDs"
}