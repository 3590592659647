import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Router } from './router';
import { VisaInstantContextProvider } from './context';

let theme = createTheme({
  palette:{
    type: 'light',
    secondary: {
      light: '#fb5658',
      main: '#c2192f',
      dark: '#8a0008',
      contrastText: '#ffffff'
    },
    primary: {
      light: '#7098dc',
      main: '#3c6aaa',
      dark: '#00407a',
      contrastText: '#ffffff'
    }
  }
});

theme = responsiveFontSizes(theme);

const App = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <VisaInstantContextProvider>
        <Router />
      </VisaInstantContextProvider>
    </ThemeProvider>
  </React.Fragment>
)

export default App;
