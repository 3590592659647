import React from 'react';
import { Button, createStyles, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import NewCardImg from '../assets/img/newcard.svg';
import { Link } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import PublicIcon from '@material-ui/icons/Public';
import SecurityIcon from '@material-ui/icons/Security';
import UpdateIcon from '@material-ui/icons/Update';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EuroIcon from '@material-ui/icons/Euro';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { Settings } from '../services/types/settings.type';
import utils from '../services/utils.service';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: 400,
      maxWidth: '100%'
    },
  }),
);

export const NoCardRequests = ({settings}: {settings:Settings}) => {

  const { t } = useTranslation('dashboard');
  const classes = useStyles();
  console.log(settings);

  return (
    <>
      <Grid container alignContent='center' spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item>
              <img className={classes.image} src={NewCardImg} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' align='center' gutterBottom>
            {t('NEW_CARD_TITLE')}
          </Typography>
          <Typography variant='body1'>
            {t('NEW_CARD_INTRO')}
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <PeopleIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary={t('CARD_FEATURE_1')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FreeBreakfastIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary={t('CARD_FEATURE_2')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PublicIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary={t('CARD_FEATURE_3')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SecurityIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary={t('CARD_FEATURE_4')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <UpdateIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary={t('CARD_FEATURE_5')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <VerifiedUserIcon color='primary' />
              </ListItemIcon>
              <ListItemText primary={t('CARD_FEATURE_6')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <EuroIcon color='primary' />
              </ListItemIcon>
              <ListItemText
                primary={t('CARD_FEATURE_7',{topupAmount: utils.currencyFormat(settings.topup_amount)})}
                secondary={t('CARD_FEATURE_7_DETAILS')} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AddShoppingCartIcon color='primary' />
              </ListItemIcon>
              <ListItemText
                primary={t('CARD_FEATURE_8',{cardPrice: utils.currencyFormat(settings.card_price)})}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item>
              <Button
                component={Link}
                to='/new-card/destination'
                variant='contained'
                color='primary'
                size='large'>
                {t('REQUEST_NEW_CARD_BUTTON_LABEL')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </>
  )

}