import React from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useApi, useContext } from '../context'
import { useTranslation } from 'react-i18next';
import utils from '../services/utils.service';
import { ErrorDialog } from '../components/errorDialog';
import { Redirect, useHistory } from 'react-router-dom';
import { OrderWizard, OrderWizardStep } from './orderWizard';





const useStyles = makeStyles((theme: Theme) =>
  createStyles({

  }),
);

export const NewCardCheckout = () => {
  const { t, i18n } = useTranslation('newCard');
  const api = useApi();
  const classes = useStyles();
  const history = useHistory();
  const context = useContext();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const submit = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const paymentData = await api.addCardRequest({
        locale: i18n.language === 'es' ? 'es' : 'en',
        delivery_method_id: context.data.deliveryMethod!.id,
        currency_id: context.data.paymentCurrency!.id,
        name: `${context.data.deliveryAddress!.first_name} ${context.data.deliveryAddress!.last_name}`,
        address: context.data.deliveryAddress!.address,
        city: context.data.deliveryAddress!.city,
        postal_code: context.data.deliveryAddress!.postal_code,
        state: context.data.deliveryAddress!.region,
        phone: context.data.deliveryAddress!.phone,
        country_id: context.data.deliveryAddress!.country.id,
        num_cards: context.data.cardNumber!
      });
      history.push(`/payments/check?id=${paymentData.revolupay_order_id}&code=${paymentData.revolupay_order_code}`)
    }, (e) => {
      if (e) {
        setError(true);
      }
      setLoading(false);
    })
  }

  if (
    !context.data.deliveryMethod ||
    !context.data.paymentCurrency ||
    !context.data.cardPrice ||
    !context.data.cardNumber
  ) {
    return <Redirect to='/new-card/shipping' />
  }

  const getTotal = () => {
    return context.data.deliveryMethod!.price + context.data.cardPrice!;
  }

  return (
    <>
      <ErrorDialog
        open={error}
        title={t('SUBMIT_ERROR_TITLE')}
        message={t('SUBMIT_ERROR_MESSAGE')}
        onClose={() => {
          setError(false);
        }}
      />
      <OrderWizard
        canGoNext={true}
        back={() => history.push('/new-card/shipping')}
        loading={loading}
        next={submit}
        step={OrderWizardStep.CHECKOUT}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('CHECKOUT_INTRO')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' gutterBottom>
              {t('DELIVERY_ADDRESS')}
            </Typography>
            <Typography>
              {`${context.data.deliveryAddress!.first_name.toUpperCase()} ${context.data.deliveryAddress!.last_name.toUpperCase()}`}
            </Typography>
            <Typography>
              {`${context.data.deliveryAddress!.phone}`}
            </Typography>
            <Typography>
              {`${context.data.deliveryAddress!.address.toUpperCase()}`}
            </Typography>
            <Typography>
              {`${context.data.deliveryAddress!.postal_code.toUpperCase()}, ${context.data.deliveryAddress!.city.toUpperCase()}`}
            </Typography>
            <Typography>
              {`${context.data.deliveryAddress!.region.toUpperCase()}`}
            </Typography>
            <Typography>
              {`${context.data.deliveryAddress!.country.name.toUpperCase()}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' gutterBottom>
              {t('DELIVERY_METHOD')}
            </Typography>
            <Typography variant='h5'>
              {`${utils.getLocalizedText(i18n, context.data.deliveryMethod!.name)}`}
            </Typography>
            <Typography variant='body2'>
              {`${utils.getLocalizedText(i18n, context.data.deliveryMethod!.description)}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('PAYMENT')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='subtitle2'>
              {t('CARD_NUMBER')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle1' align='right'>
              {`${context.data.cardNumber!}`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='subtitle2'>
              {t('CARD_ISSUE_FEE')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle1' align='right'>
              {`${utils.currencyFormat(context.data.cardPrice! / context.data.paymentCurrency!.rate)} ${context.data.paymentCurrency!.iso}`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='subtitle2'>
              {t('SHIPPING_FEE')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle1' align='right'>
              {`${utils.currencyFormat(context.data.deliveryMethod!.price / context.data.paymentCurrency!.rate)} ${context.data.paymentCurrency!.iso}`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='h5'>
              {t('TOTAL')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6' align='right'>
              {`${utils.currencyFormat(getTotal() / context.data.paymentCurrency!.rate)} ${context.data.paymentCurrency!.iso}`}
            </Typography>
          </Grid>
        </Grid>
      </OrderWizard>
    </>
  )
}