import {
    Box,
    Grid,
    Typography,
    Button,
    MenuItem,
    Menu,
    LinearProgress,
    makeStyles,
    createStyles,
    Theme,
    IconButton
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Card as CardComponent } from '../components/card';
import { ErrorDialog } from '../components/errorDialog';
import { Loading } from '../components/loading';
import { useApi } from '../context';
import { Card } from '../services/types/card.type';
import { CardBalance } from '../services/types/cardBalance.type';
import { CardLimit } from '../services/types/cardLimit.type';
import { Topup } from '../services/types/topup.type';
import { Transaction } from '../services/types/transaction.type';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import utils from '../services/utils.service';
import { TopupRow } from './topupRow';
import moment from 'moment';
import { TransactionRow } from './transactionRow';
import { TopupStatus } from '../services/types/topupStatus.enum';
import NewCardImg from '../assets/img/newcard.svg';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Config } from '../config';
import { ConfirmDialog } from '../components/confirmDialog';
import { SuccessDialog } from '../components/successDialog';
import { CardStatus } from '../services/types/cardStatus.enum';
import HelpIcon from '@material-ui/icons/Help';
import { AlertDialog } from '../components/alertDialog';

const config = new Config();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            marginTop: theme.spacing(2),
            width: 240,
            maxWidth: '100%'
        },
        balanceHelpButton: {
            marginTop: -30
        }
    }),
);

const Actions = ({
    cardId,
    refresh,
    cancelled
}: {
    cardId: string;
    cancelled: boolean;
    refresh: () => void;
}) => {
    const { t } = useTranslation('card');
    const history = useHistory();
    const api = useApi();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showRequestPinDialog, setShowRequestPinDialog] = React.useState(false);
    const [showBlockCardDialog, setShowBlockCardDialog] = React.useState(false);
    const [showRequestPinSuccess, setShowRequestPinSuccess] = React.useState(false);
    const [showBlockCardSuccess, setShowBlockCardSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const requestPin = () => {
        utils.runAsync(async () => {
            setLoading(true);
            await api.requestPin(cardId);
            setShowRequestPinDialog(false);
            setShowRequestPinSuccess(true);
        }, (e) => {
            setLoading(false);
        });
    }
    const blockCard = () => {
        utils.runAsync(async () => {
            setLoading(true);
            await api.blockCard(cardId);
            setShowBlockCardDialog(false);
            setShowBlockCardSuccess(true);
        }, (e) => {
            setLoading(false);
        });
    }

    return (
        <>
            <ConfirmDialog
                open={showRequestPinDialog}
                title={t('REQUEST_PIN_CONFIRM_DIALOG_TITLE')}
                message={t('REQUEST_PIN_CONFIRM_DIALOG_DESCRIPTION')}
                loading={loading}
                onCancel={() => {
                    setShowRequestPinDialog(false);
                }}
                onConfirm={() => {
                    requestPin();
                }}
            />
            <ConfirmDialog
                open={showBlockCardDialog}
                title={t('BLOCK_CARD_CONFIRM_DIALOG_TITLE')}
                message={t('BLOCK_CARD_CONFIRM_DIALOG_DESCRIPTION')}
                loading={loading}
                onCancel={() => {
                    setShowBlockCardDialog(false);
                }}
                onConfirm={() => {
                    blockCard();
                }}
            />
            <SuccessDialog
                open={showRequestPinSuccess}
                title={t('REQUEST_PIN_SUCCESS_DIALOG_TITLE')}
                message={t('REQUEST_PIN_SUCCESS_DIALOG_DESCRIPTION')}
                onClose={() => {
                    setShowRequestPinSuccess(false);
                }} />
            <SuccessDialog
                open={showBlockCardSuccess}
                title={t('BLOCK_CARD_SUCCESS_DIALOG_TITLE')}
                message={t('BLOCK_CARD_SUCCESS_DIALOG_DESCRIPTION')}
                onClose={() => {
                    setShowBlockCardSuccess(false);
                    refresh();
                }} />
            <Button onClick={openMenu} variant='contained' color='primary' endIcon={<ArrowDropDownIcon />}>{t('ACTIONS')}</Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                <MenuItem disabled={cancelled} onClick={() => {
                    closeMenu();
                    history.push(`/cards/${cardId}/topup`);
                }}>
                    {t('TOPUP')}
                </MenuItem>
                <MenuItem onClick={() => {
                    closeMenu();
                    history.push(`/cards/${cardId}/transactions`);
                }}>
                    {t('VIEW_TRANSACTIONS')}
                </MenuItem>
                <MenuItem disabled={cancelled} onClick={() => {
                    closeMenu();
                    setShowRequestPinDialog(true);
                }}>
                    {t('REQUEST_PIN')}
                </MenuItem>
                <MenuItem disabled={cancelled} onClick={() => {
                    closeMenu();
                    setShowBlockCardDialog(true);
                }}>
                    {t('BLOCK_CARD')}
                </MenuItem>
            </Menu>
        </>
    );
}

export const CardOverview = () => {
    const { t, i18n } = useTranslation('card');
    const { cardId } = useParams<{ cardId: string }>();
    const api = useApi();
    const history = useHistory();
    const classes = useStyles();

    const [error, setError] = React.useState(false);
    const [showBalanceInfo, setShowBalanceInfo] = React.useState(false);

    const [loadingCards, setLoadingCards] = React.useState(true);
    const [loadingBalance, setLoadingBalance] = React.useState(true);
    const [loadingTopups, setLoadingTopups] = React.useState(true);
    const [loadingTransactions, setLoadingTransactions] = React.useState(true);
    const [loadingLimits, setLoadingLimits] = React.useState(true);

    const [card, setCard] = React.useState<Card | undefined>();
    const [balance, setBalance] = React.useState<CardBalance | undefined>();
    const [topups, setTopups] = React.useState<Topup[]>([]);
    const [transactions, setTransactions] = React.useState<Transaction[]>([]);
    const [limits, setLimits] = React.useState<CardLimit[]>([]);


    const loadCard = () => {
        utils.runAsync(async () => {
            setLoadingCards(true);
            const cards = (await api.getCards()).data;
            const filteredCard = cards.find(c => c.id === parseInt(cardId, 10));
            if (filteredCard) {
                setCard(filteredCard);
            }
        }, (e) => {
            setLoadingCards(false);
            if (e) {
                setError(true);
            }
        });
    }

    const loadBalance = () => {
        utils.runAsync(async () => {
            setLoadingBalance(true);
            setBalance(await api.getCardBalance(cardId));
        }, (e) => {
            setLoadingBalance(false);
            if (e) {
                setError(true);
            }
        });
    }

    const loadTopups = () => {
        utils.runAsync(async () => {
            setLoadingTopups(true);
            const unfilteredTopups = (await api.getCardTopups(cardId, 50, 0)).data
            setTopups(
                unfilteredTopups
                    .filter(t => [TopupStatus.NEW, TopupStatus.PROCESSED].includes(t.status))
                    .sort((a, b) => moment(b.created).unix() - moment(a.created).unix())
            );
        }, (e) => {
            setLoadingTopups(false);
            if (e) {
                setError(true);
            }
        });
    }

    const loadTransactions = () => {
        utils.runAsync(async () => {
            setLoadingTransactions(true);
            setTransactions((await api.getCardTransactions(cardId)).data);
        }, (e) => {
            setLoadingTransactions(false);
            if (e) {
                setError(true);
            }
        });
    }

    const loadLimits = () => {
        utils.runAsync(async () => {
            setLoadingLimits(true);
            setLimits(await api.getCardLimits(cardId));
        }, (e) => {
            setLoadingLimits(false);
            if (e) {
                setError(true);
            }
        });
    }

    const loadData = () => {
        loadCard();
        loadBalance();
        loadTopups();
        loadTransactions();
        loadLimits();
    }

    React.useEffect(() => {
        loadData();
    }, []);


    moment.locale(i18n.language);

    if (loadingCards) {
        return <Loading />
    }

    if (card === undefined) {
        return <Redirect to='/dashboard' />
    }

    return (
        <>
            <ErrorDialog
                open={error}
                title={t('LOADING_ERROR_TITLE')}
                message={t('LOADING_ERROR_MESSAGE')}
                onClose={() => {
                    setError(false);
                    loadData();
                }}
            />
            <AlertDialog
                open={showBalanceInfo}
                title={t('BALANCE_INFO_TITLE')}
                message={t('BALANCE_INFO_MESSAGE')}
                onClose={() => {
                    setShowBalanceInfo(false);
                }}
            />
            <Grid container alignContent='flex-start' spacing={4}>
                {config.ALLOW_MULTIPLE_CARDS &&
                    <Grid item xs={12}>
                        <Button
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => {
                                history.push('/dashboard')
                            }} >
                            {t('BACK_TO_CARD_SELECTOR')}
                        </Button>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Grid container spacing={1} alignContent='stretch'>
                        <Grid item container xs={12} justifyContent='center'>
                            <CardComponent card={card} />
                        </Grid>
                        <Grid container item xs={12} justifyContent='center'>
                            <Actions
                                cancelled={card.status === CardStatus.BLOCKED || card.status === CardStatus.CANCELLED}
                                cardId={cardId}
                                refresh={loadData} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                        <Grid item xs={12}>
                            <Typography variant='h4' align='center'>
                                {t('BALANCE')}
                                <IconButton
                                    color='secondary'
                                    size='small'
                                    className={classes.balanceHelpButton}
                                    onClick={() => {
                                        setShowBalanceInfo(true);
                                    }}>
                                    <HelpIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} justifyContent='center'>
                            {(!balance || loadingBalance) &&
                                <Skeleton height={60} width={100} />
                            }
                            {(balance && !loadingBalance) &&
                                <Typography variant='h3' align='center'>
                                    {`${utils.currencyFormat(balance.accountable)} EUR`}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent='center'>
                    {loadingLimits &&
                        <Box >
                            <Skeleton width={200} height={40} />
                        </Box>
                    }
                    {(!loadingLimits && limits.length > 0) &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant={'h5'} gutterBottom align='center'>
                                    {t('LIMITS')}
                                </Typography>
                            </Grid>
                            {limits.map(l => (
                                <Grid key={l.frequency} item xs={12}>
                                    <Box margin='0 auto' marginTop={2}>
                                        <Box display={'flex'} justifyContent='space-between'>
                                            <Typography>
                                                {`${utils.currencyFormat(l.limit - l.remaining_amount)}/${utils.currencyFormat(l.limit)} EUR`}
                                            </Typography>
                                            <Typography variant='subtitle2'>
                                                {t(l.frequency)}
                                            </Typography>
                                        </Box>
                                        <LinearProgress variant='determinate' color='secondary' value={100 - (l.remaining_amount * 100 / l.limit)} />
                                        {l.resets_on &&
                                            <Box display={'flex'} justifyContent='flex-end'>
                                                <Typography variant='body2'>
                                                    {t('RESETS_ON', { date: moment(l.resets_on).format('ll') })}
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>

                            ))}
                        </>

                    }
                </Grid>
                <Grid item container xs={12} justifyContent='center'>
                    {loadingTopups &&
                        <Box >
                            <Skeleton width={200} height={40} />
                            <Skeleton width={200} height={40} />
                            <Skeleton width={200} height={40} />
                        </Box>
                    }
                    {(!loadingTopups && topups.length > 0) &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant={'h5'} gutterBottom align='center'>
                                    {t('PROCESSING_TOPUPS')}
                                </Typography>
                            </Grid>
                            <Grid item container>
                                {topups.map(tr =>
                                    <TopupRow key={tr.id} topup={tr} />
                                )}
                            </Grid>
                        </>
                    }
                </Grid>
                <Grid item container xs={12} justifyContent='center'>
                    {loadingTransactions &&
                        <Box >
                            <Skeleton width={200} height={40} />
                            <Skeleton width={200} height={40} />
                            <Skeleton width={200} height={40} />
                        </Box>
                    }
                    {(!loadingTransactions) &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant={'h5'} gutterBottom align='center'>
                                    {t('RECENT_TRANSACTIONS')}
                                </Typography>
                            </Grid>
                            <Grid item container>
                                {transactions.map(tr =>
                                    <TransactionRow key={tr.id} transaction={tr} />
                                )}
                            </Grid>
                            {transactions.length > 0 &&
                                <Grid item>
                                    <Button onClick={() => history.push(`/cards/${cardId}/transactions`)}>{t('VIEW_ALL')}</Button>
                                </Grid>
                            }
                        </>
                    }
                    {(!loadingTransactions && transactions.length === 0) &&
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent='center'>
                                        <Grid item>
                                            <img className={classes.image} src={NewCardImg} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography align='center'>
                                        {t('TRANSACTIONS_PLACEHOLDER_DESCRIPTION')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )

}